<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <div style="margin-bottom: 10px">
        <b-button variant="primary" @click="exportExcel('2022')"
          ><i class="fa fa-download" aria-hidden="true" />Export PM PTSP
          Pemda(2022)
        </b-button>
        <b-button variant="primary" @click="exportExcel('2023')" class="ml-1"
          ><i class="fa fa-download" aria-hidden="true" />Export PM PTSP
          Pemda(2023)
        </b-button>
        <b-button variant="primary" @click="exportExcel('2024')" class="ml-1"
          ><i class="fa fa-download" aria-hidden="true" />Export PM PTSP
          Pemda(2024)
        </b-button>
      </div>
      <!--begin: Wizard-->
      <div
        class="wizard wizard-pm"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper row">
                <div class="wizard-number col-3">1</div>
                <div class="wizard-label col-9">
                  <div class="wizard-title">Kelembagaan</div>
                  <div class="wizard-desc">Penilaian Kelembagaan</div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper row">
                <div class="wizard-number col-3">2</div>
                <div class="wizard-label col-9">
                  <div class="wizard-title">SDM</div>
                  <div class="wizard-desc">Penilaian Sumber Daya Manusia</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper row">
                <div class="wizard-number col-3">3</div>
                <div class="wizard-label col-9">
                  <div class="wizard-title">Sarpras</div>
                  <div class="wizard-desc">Penilaian Sarana dan Prasarana</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper row">
                <div class="wizard-number col-3">4</div>
                <div class="wizard-label col-9">
                  <div class="wizard-title">OSS</div>
                  <div class="wizard-desc">Penilaian Impementasi OSS</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper row">
                <div class="wizard-number col-3">5</div>
                <div class="wizard-label col-9">
                  <div class="wizard-title">Keluaran</div>
                  <div class="wizard-desc">Penilaian Keluaran</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-12 px-0 py-lg-15 px-lg-0">
              <div class="col-xl-12 col-xxl-10">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div v-if="pm.catatan_1_surveyor != null">
                      <b-alert show variant="danger"
                        >Terdapat catatan, silahkan lihat catatan
                        dibawah!</b-alert
                      >
                    </div>
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Kelembagaan PTSP
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <span style="color: yellow; font-weight: bold"
                            >halaman depan</span
                          >
                          dan
                          <span style="color: yellow; font-weight: bold"
                            >halaman pengesahan</span
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <span style="color: yellow; font-weight: bold"
                            >500KB</span
                          >
                          dengan format file
                          <span style="color: yellow; font-weight: bold"
                            >*.pdf</span
                          >.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b>Integritas dan Kepatuhan</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">1</td>
                            <td rowspan="5">
                              Apakah tersedia SOP Pelayanan Perizinan Berusaha?
                            </td>
                            <td colspan="4">
                              Untuk menilai ketersediaan SOP Pelayanan Perizinan
                              Berusaha dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP Pelayanan Perizinan Berusaha telah
                                  ditetapkan melalui Keputusan Kepala Daerah
                                  atau Keputusan Kepala DPMPTSP
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1a1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1a1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1a1_file1"
                                v-b-tooltip.hover
                                title="SOP Pelayanan Perizinan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1a1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1a1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1a1_file1 != null"
                                @click="unduhFile(pm.p1a1_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP Pelayanan Perizinan Berusaha mencakup
                                  seluruh bidang usaha Klasifikasi Baku Lapangan
                                  Usaha Indonesia (KBLI) yang menjadi
                                  kewenangannya
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1a1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1a1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1a1_file2"
                                v-b-tooltip.hover
                                title="SOP Pelayanan Perizinan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1a1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1a1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1a1_file2 != null"
                                @click="unduhFile(pm.p1a1_file2)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP Pelayanan Perizinan Berusaha dapat diakses
                                  oleh publik secara konvensional atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1a1_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1a1_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1a1_file3"
                                v-b-tooltip.hover
                                title="Bukti SOP dapat diakses (Foto, URL, dll)"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1a1_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1a1_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1a1_file3 != null"
                                @click="unduhFile(pm.p1a1_file3)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lengkap” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak lengkap” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">Seberapa patuh terhadap NSPK?</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Penilaian kepatuhan terhadap NSPK, Peraturan
                                  Kepala Daerah (Perkada) yang telah diterbitkan
                                  dengan mengacu pada PP. No. 5 Tahun 2021 dan
                                  PP. No. 6 Tahun 2021.
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1a2_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1a2_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1a2_file1"
                                v-b-tooltip.hover
                                title="Dokumen Perkada Perizinan Berusaha yang merujuk PP 5 dan  PP 6 Tahun 2021"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1a2_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1a2_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1a2_file1 != null"
                                @click="unduhFile(pm.p1a2_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Patuh” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak patuh” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">3</td>
                            <td rowspan="5">
                              Apakah ada dokumen Standar Pelayanan Publik (SPP)?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya dokumen Standar Pelayanan
                              Publik [SPP] dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumen [SPP] ditetapkan melalui Keputusan
                                  Kepala Daerah atau Keputusan Kepala DPMPTSP
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1a3_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1a3_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1a3_file1"
                                v-b-tooltip.hover
                                title="Dokumen SPP"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1a3_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1a3_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1a3_file1 != null"
                                @click="unduhFile(pm.p1a3_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumen [SPP] mengacu Permenpan RB No. 15
                                  Tahun 2014 tentang Pedoman Standar Pelayanan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1a3_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1a3_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1a3_file2"
                                v-b-tooltip.hover
                                title="Dokumen SPP"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1a3_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1a3_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1a3_file2 != null"
                                @click="unduhFile(pm.p1a3_file2)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumen [SPP] dapat diakses oleh publik secara
                                  konvensional atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1a3_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1a3_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1a3_file3"
                                v-b-tooltip.hover
                                title="Bukti SPP dapat diakses (Foto, URL, dll)"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1a3_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1a3_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1a3_file3 != null"
                                @click="unduhFile(pm.p1a3_file3)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketiga ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">4</td>
                            <td rowspan="4">
                              Apakah ada ketersediaan Pakta Integritas?
                            </td>
                            <td colspan="4">
                              Penilaian ketersediaan Pakta Integritas dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Pakta Integritas tersedia di ruang pelayanan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1a4_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1a4_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1a4_file1"
                                v-b-tooltip.hover
                                title="Foto Pakta Integritas di ruang pelayanan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1a4_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1a4_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1a4_file1 != null"
                                @click="unduhFile(pm.p1a4_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Pejabat tertinggi yang membidangi Pelayanan
                                  Perizinan Berusaha memiliki Pakta Integritas
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1a4_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1a4_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1a4_file2"
                                v-b-tooltip.hover
                                title="Bukti Pakta Integritas yang di TTD Kadis DPMPTSP"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1a4_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1a4_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1a4_file2 != null"
                                @click="unduhFile(pm.p1a4_file2)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lengkap” apabila kedua
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b>Kewenangan</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada pelimpahan kewenangan?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya pelimpahan kewenangan
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia dokumen pelimpahan kewenangan sebagai
                                  berikut:
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  a. Untuk pemerintah provinsi, pendelegasian
                                  kewenangan oleh gubernur meliputi:
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  <div class="row">
                                    <div class="col-1">
                                      <span class="ml-1"> 1 </span>
                                    </div>
                                    <div class="col-11">
                                      Penyelenggaraan Perizinan Berusaha seluruh
                                      KBLI yang menjadi kewenangan pemerintah
                                      daerah provinsi sesuai dengan ketentuan
                                      peraturan perundang-undangan
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  <div class="row">
                                    <div class="col-1">
                                      <span class="ml-1"> 2 </span>
                                    </div>
                                    <div class="col-11">
                                      Penyelenggaraan Perizinan Berusaha seluruh
                                      KBLI yang menjadi kewenangan Pemerintah
                                      Pusat yang dilimpahkan kepada gubernur
                                      berdasarkan asas dekonsentrasi dan tugas
                                      pembantuan.
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  b. Untuk pemerintah kabupaten/kota,
                                  pendelegasian kewenangan oleh bupati/wali kota
                                  meliputi:
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  <div class="row">
                                    <div class="col-1">
                                      <span class="ml-1"> 1 </span>
                                    </div>
                                    <div class="col-11">
                                      Penyelenggaraan Perizinan Berusaha seluruh
                                      KBLI yang menjadi kewenangan Pemerintah
                                      Daerah kabupaten/kota sesuai dengan
                                      ketentuan peraturan perundang-undangan
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  <div class="row">
                                    <div class="col-1">
                                      <span class="ml-1"> 2 </span>
                                    </div>
                                    <div class="col-11">
                                      Penyelenggaraan Perizinan Berusaha seluruh
                                      KBLI yang menjadi kewenangan Pemerintah
                                      Pusat yang dilimpahkan kepada bupati/wali
                                      kota berdasarkan asas tugas pembantuan
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1b1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1b1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1b1_file1"
                                v-b-tooltip.hover
                                title="Mengunggah Perkada Pelimpahan Wewenang yang merujuk PP 5 dan PP6 Tahun 2021"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1b1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1b1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1b1_file1 != null"
                                @click="unduhFile(pm.p1b1_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Meliputi seluruh perizinan berusaha yang
                                  menjadi kewenangan pemerintah daerah provinsi,
                                  dan pemerintah daerah kabupaten/kota
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1b1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1b1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1b1_file2"
                                v-b-tooltip.hover
                                title="Mengunggah Perkada Pelimpahan Wewenang yang merujuk PP 5 dan PP6 Tahun 2021"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1b1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1b1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1b1_file2 != null"
                                @click="unduhFile(pm.p1b1_file2)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada sepenuhnya” apabila kedua
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada sebagian” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">Apakah ada target retribusi?</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Penilaian terkait ada tidak adanya target
                                  retribusi melalui surat pernyataan Kadis
                                  DPMPTSP terkait
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1b2_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1b2_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1b2_file1"
                                v-b-tooltip.hover
                                title="Surat pernyataan Kadis DPMPTSP terkait tidak adanya target retribusi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1b2_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1b2_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1b2_file1 != null"
                                @click="unduhFile(pm.p1b2_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian ada tidaknya target retribusi
                                    dilakukan dengan ketentuan: <br />
                                    1. Nilai “Tidak ada” apabila pemerintah
                                    daerah dapat membuktikan tentang tidak
                                    adanya target retribusi dengan
                                    sekurang-kurangnya melalui surat pernyataan
                                    dari Kepala DPMPTSP.<br />
                                    2. Nilai “Ada” apabila ketentuan di atas
                                    tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b>Tanggung Jawab</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">Apakah ada mekanisme pengaduan?</td>
                            <td colspan="3">
                              Penilaian ada tidaknya mekanisme pengaduan
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia dokumen SOP layanan pengaduan yang
                                  dapat diakses dengan penggunaan teknologi
                                  informasi dan komunikasi atau secara
                                  konvensional, misalnya:
                                  <em>flyer</em>, brosur,
                                  <em>standing banner</em>, dll
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1c1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1c1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1c1_file1"
                                v-b-tooltip.hover
                                title="Dokumen/SOP Pengaduan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1c1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1c1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1c1_file1 != null"
                                @click="unduhFile(pm.p1c1_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat layanan pengaduan terjadwal.
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1c1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1c1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1c1_file2"
                                v-b-tooltip.hover
                                title="Jadwal layanan pengaduan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1c1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1c1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1c1_file2 != null"
                                @click="unduhFile(pm.p1c1_file2)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Bagaimana tingkat penyelesaian pengaduan?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  @change="onFileChangedp1c2_file1_radio"
                                  v-model="pm.p1c2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c2_nilai"
                                  value="25"
                                  >Semua selesai</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp1c2_file1_radio"
                                  v-model="pm.p1c2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c2_nilai"
                                  value="10"
                                  >Sebagian selesai</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp1c2_file1_radio"
                                  v-model="pm.p1c2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c2_nilai"
                                  value="0"
                                  >Tidak ada yang selesai</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1c2_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1c2_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1c2_file1"
                                v-b-tooltip.hover
                                title="Daftar pengaduan dan tingkat penyelesaiannya"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1c2_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1c2_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1c2_file1 != null"
                                @click="unduhFile(pm.p1c2_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian ada tidaknya target retribusi
                                    dilakukan dengan ketentuan: <br />
                                    1. Nilai “Semua selesai” apabila jumlah
                                    pengaduan yang masuk sebanding dengan jumlah
                                    pengaduan yang diselesaikan.<br />
                                    2. Nilai “Sebagian selesai” apabila jumlah
                                    pengaduan yang masuk diselesaikan sebagian.
                                    <br />
                                    3. Nilai “Tidak ada yang selesai” apabila
                                    tidak ada pengaduan yang telah diselesaikan.
                                    <br />
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">3</td>
                            <td rowspan="2">
                              Seberapa cepat waktu penyelesaian masalah atau
                              pengaduan?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  @change="onFileChangedp1c3_file1_radio"
                                  v-model="pm.p1c3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c3_nilai"
                                  value="25"
                                  >Lebih cepat dari SOP</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp1c3_file1_radio"
                                  v-model="pm.p1c3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c3_nilai"
                                  value="10"
                                  >Sesuai SOP</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp1c3_file1_radio"
                                  v-model="pm.p1c3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c3_nilai"
                                  value="0"
                                  >Tidak sesuai</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1c3_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1c3_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1c3_file1"
                                v-b-tooltip.hover
                                title="Dokumen daftar pengaduan yang masuk, tingkat penyelesaian, dan halaman waktu penyelesaian di SOP nya"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1c3_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1c3_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1c3_file1 != null"
                                @click="unduhFile(pm.p1c3_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian kecepatan waktu penyelesaian
                                    masalah atau pengaduan dilakukan dengan
                                    ketentuan: <br />
                                    1. Nilai “lebih cepat dari SOP” apabila
                                    waktu rata-rata penyelesaian masalah atau
                                    pengaduan lebih cepat daripada waktu yang
                                    dimuat dalam SOP.<br />
                                    2. Nilai “Sesuai SOP” apabila waktu
                                    rata-rata penyelesaian masalah atau
                                    pengaduan sama dengan waktu yang dimuat
                                    dalam SOP. <br />
                                    3. Nilai “Tidak sesuai” apabila waktu
                                    rata-rata penyelesaian masalah atau
                                    pengaduan lebih lama daripada waktu yang
                                    dimuat dalam SOP. <br />
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">4</td>
                            <td rowspan="5">
                              Apakah ada layanan <em>helpdesk</em>?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya <em>helpdesk</em> dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Layanan <em>helpdesk</em> dapat diakses dengan
                                  menggunakan teknologi informasi dan komunikasi
                                  atau konvensional
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1c4_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1c4_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1c4_file1"
                                v-b-tooltip.hover
                                title="Bukti ketersediaan helpdesk (Foto, URL, dll)"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1c4_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1c4_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1c4_file1 != null"
                                @click="unduhFile(pm.p1c4_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  <em>Helpdesk</em> secara konvensional memiliki
                                  ruang/loket dan petugas tersendiri.
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1c4_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1c4_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1c4_file2"
                                v-b-tooltip.hover
                                title="Foto loket/ruang daftar waktu dan nama petugas helpdesk"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1c4_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1c4_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1c4_file2 != null"
                                @click="unduhFile(pm.p1c4_file2)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Layanan <em>Helpdesk</em> diberikan setiap
                                  hari kerja
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1c4_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1c4_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1c4_file3"
                                v-b-tooltip.hover
                                title="Daftar waktu dan nama petugas helpdesk"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1c4_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1c4_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1c4_file3 != null"
                                @click="unduhFile(pm.p1c4_file3)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketiga ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b>Kesinambungan</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan koordinasi internal?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya kegiatan koordinasi
                              internal dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan koordinasi internal dilakukan
                                  sekurang-kurangnya 1 kali dalam 1 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d1_file1"
                                v-b-tooltip.hover
                                title="Dokumen undangan rapat koordinasi internal"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d1_file1 != null"
                                @click="unduhFile(pm.p1d1_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d1_file2"
                                v-b-tooltip.hover
                                title="Dokumen undangan rapat koordinasi internal"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d1_file2 != null"
                                @click="unduhFile(pm.p1d1_file2)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d1_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d1_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d1_file3"
                                v-b-tooltip.hover
                                title="Daftar hadir"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d1_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d1_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d1_file3 != null"
                                @click="unduhFile(pm.p1d1_file3)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula kegiatan koordinasi internal
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d1_file4"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d1_file4"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d1_file4"
                                v-b-tooltip.hover
                                title="Notula"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d1_file4 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d1_file4')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d1_file4 != null"
                                @click="unduhFile(pm.p1d1_file4)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d1_file5"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d1_file5"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d1_file5"
                                v-b-tooltip.hover
                                title="Foto kegiatan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d1_file5 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d1_file5')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d1_file5 != null"
                                @click="unduhFile(pm.p1d1_file5)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, terjadwal” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada tidak terjadwal” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Berapa nilai Indeks Kepuasan Masyarakat (IKM)?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  @change="onFileChangedp1d2_file1_radio"
                                  v-model="pm.p1d2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1d2_nilai"
                                  value="25"
                                  >Di atas 75</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp1d2_file1_radio"
                                  v-model="pm.p1d2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1d2_nilai"
                                  value="10"
                                  >Di bawah 75</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp1d2_file1_radio"
                                  v-model="pm.p1d2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1d2_nilai"
                                  value="0"
                                  >Tidak ada</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d2_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d2_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d2_file1"
                                v-b-tooltip.hover
                                title="Dokumen hasil survei IKM"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d2_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d2_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d2_file1 != null"
                                @click="unduhFile(pm.p1d2_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Di atas 75” apabila IKM
                                    mendapatkan hasil di atas 75.<br />
                                    2. Nilai “Di bawah 75” apabila IKM
                                    mendapatkan hasil di bawah 75. <br />
                                    3. Nilai “Tidak ada” apabila tidak dilakukan
                                    survei IKM .<br />
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">3</td>
                            <td rowspan="5">
                              Bagaimana tingkat kecukupan dan kualitas kegiatan
                              inovasi?
                            </td>
                            <td colspan="4">
                              Penilaian kecukupan dan kualitas kegiatan inovasi
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan inovasi tersedia lebih dari 2 bentuk
                                  inovasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d3_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d3_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d3_file1"
                                v-b-tooltip.hover
                                title="Daftar dan foto inovasi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d3_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d3_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d3_file1 != null"
                                @click="unduhFile(pm.p1d3_file1)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Seluruh kegiatan inovasi masih berjalan hingga
                                  periode penilaian
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d3_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d3_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d3_file2"
                                v-b-tooltip.hover
                                title="Bukti inovasi masih berjalan (Foto, Surat tugas/jalan, dll)"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d3_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d3_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d3_file2 != null"
                                @click="unduhFile(pm.p1d3_file2)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat bukti pemanfaatan seluruh kegiatan
                                  inovasi oleh masyarakat
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d3_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d3_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d3_file3"
                                v-b-tooltip.hover
                                title="Daftar hadir pengguna inovasi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d3_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d3_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d3_file3 != null"
                                @click="unduhFile(pm.p1d3_file3)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Sangat baik” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Cukup baik” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="8">4</td>
                            <td rowspan="8">
                              Apakah ada ketentuan kegiatan monitoring dan
                              evaluasi?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d4_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d4_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d4_file1"
                                v-b-tooltip.hover
                                title="Bukti kehadiran stakeholder (Foto dan daftar hadir)"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d4_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d4_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d4_file1 != null"
                                type="button"
                                @click="unduhFile(pm.p1d4_file1)"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi melibatkan
                                  <em>stakeholder</em> lain.
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d4_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d4_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d4_file2"
                                v-b-tooltip.hover
                                title="Bukti kehadiran stakeholder (Foto dan daftar hadir)"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d4_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d4_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d4_file2 != null"
                                type="button"
                                @click="unduhFile(pm.p1d4_file2)"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d4_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d4_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d4_file3"
                                v-b-tooltip.hover
                                title="Dokumen undangan rapat koordinasi internal"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d4_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d4_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d4_file3 != null"
                                type="button"
                                @click="unduhFile(pm.p1d4_file3)"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d4_file4"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d4_file4"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d4_file4"
                                v-b-tooltip.hover
                                title="Daftar hadir"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d4_file4 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d4_file4')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d4_file4 != null"
                                type="button"
                                @click="unduhFile(pm.p1d4_file4)"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula rapat kegiatan monitoring dan
                                  evaluasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d4_file5"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d4_file5"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d4_file5"
                                v-b-tooltip.hover
                                title="Notula"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d4_file5 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d4_file5')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d4_file5 != null"
                                @click="unduhFile(pm.p1d4_file5)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file6 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumentasi rapat dalam bentuk foto digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d4_file6"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d4_file6"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d4_file6"
                                v-b-tooltip.hover
                                title="Foto kegiatan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d4_file6 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d4_file6')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d4_file6 != null"
                                @click="unduhFile(pm.p1d4_file6)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, terjadwal” apabila keenam
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada tidak terjadwal” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="6">5</td>
                            <td rowspan="6">
                              Apakah ada evaluasi dan tindak lanjut hasil
                              penilaian kinerja periode sebelumnya?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya evaluasi dan tindak lanjut
                              hasil penilaian kinerja periode sebelumnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d5_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d5_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d5_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d5_file1"
                                v-b-tooltip.hover
                                title="Dokumen undangan rapat koordinasi dengan pihak eksternal"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d5_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d5_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d5_file1 != null"
                                type="button"
                                @click="unduhFile(pm.p1d5_file1)"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d5_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d5_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d5_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d5_file2"
                                v-b-tooltip.hover
                                title="Daftar hadir"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d5_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d5_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d5_file2 != null"
                                @click="unduhFile(pm.p1d5_file2)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d5_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula rapat kegiatan monitoring dan
                                  evaluasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d5_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d5_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d5_file3"
                                v-b-tooltip.hover
                                title="Notula"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d5_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d5_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d5_file3 != null"
                                @click="unduhFile(pm.p1d5_file3)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d5_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumentasi rapat dalam bentuk foto digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d5_file4"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d5_file4"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d5_file4"
                                v-b-tooltip.hover
                                title="Foto Kegiatan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d5_file4 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d5_file4')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d5_file4 != null"
                                @click="unduhFile(pm.p1d5_file4)"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila keempat ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="6">6</td>
                            <td rowspan="6">
                              Apakah ada laporan kinerja pelayanan investasi?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya laporan berkala kinerja
                              pelayanan investasi dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi dilakukan
                                  secara berkala sekurang-kurangnya setiap 3
                                  (tiga) bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d6_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d6_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d6_file1"
                                v-b-tooltip.hover
                                title="Laporan kinerja pelayanan PB selama Tahun 2023"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d6_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d6_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d6_file1 != null"
                                type="button"
                                @click="unduhFile(pm.p1d6_file1)"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi
                                  sekurang-kurangnya memuat:
                                  <tabel
                                    cellspacing="0"
                                    cellpadding="0"
                                    style="border: none"
                                  >
                                    <tr>
                                      <td>1.</td>
                                      <td>
                                        Jumlah perizinan berusaha yang terbit
                                        (untuk seluruh risiko)
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>2.</td>
                                      <td>
                                        Data rencana dan realisasi investasi
                                      </td>
                                    </tr>
                                  </tabel>
                                  <!-- <div class="row">
                                                                        <div class="col-1">
                                                                            1.
                                                                        </div>
                                                                        <div class="col-11">
                                                                            Jumlah perizinan berusaha yang terbit (untuk
                                                                            seluruh risiko)
                                                                        </div>
                                                                        <div class="col-1">
                                                                            2.
                                                                        </div>
                                                                        <div class="col-11">
                                                                            Data rencana dan realisasi investasi
                                                                        </div>
                                                                    </div> -->
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d6_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d6_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d6_file2"
                                v-b-tooltip.hover
                                title="Laporan kinerja pelayanan PB selama Tahun 2023"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d6_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d6_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d6_file2 != null"
                                type="button"
                                @click="unduhFile(pm.p1d6_file2)"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi
                                  sekurang-kurangnya memuat kendala dan solusi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d6_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d6_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d6_file3"
                                v-b-tooltip.hover
                                title="Halaman yang memuat masalah dan solusi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d6_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d6_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d6_file3 != null"
                                type="button"
                                @click="unduhFile(pm.p1d6_file3)"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi dibuat
                                  secara formal dalam bentuk dokumen laporan
                                  lengkap fisik atau digital, bukan bahan
                                  presentasi dan sejenisnya
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp1d6_file4"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p1d6_file4"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp1d6_file4"
                                v-b-tooltip.hover
                                title="Laporan kinerja pelayanan PB selama Tahun 2023"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p1d6_file4 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p1d6_file4')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p1d6_file4 != null"
                                type="button"
                                @click="unduhFile(pm.p1d6_file4)"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, berkala” apabila keempat
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak berkala” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <!-- <div class="row">
                            <div class="col-6">

                            </div>
                            <div class="col-6">
                                <button type="button" @click="getjumlah('1')" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase"><i class="fas fa-save"></i>Simpan Form</button>
                            </div>
                        </div>
                        <br> -->
                      <div v-if="pm.catatan_1_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_1_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="pm.catatan_2_surveyor != null">
                      <b-alert show variant="danger"
                        >Terdapat catatan, silahkan lihat catatan
                        dibawah!</b-alert
                      >
                    </div>
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan SDM PTSP berdasarkan
                          fakta yang ada dengan memilih data yang sesuai.
                          Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="card card-custom">
                      <div class="card-body">
                        <b-button
                          v-b-modal.modal-prevent-closing
                          class="m-1"
                          style="background-color: #013368; color: white"
                          ><i
                            style="color: white"
                            class="flaticon2-plus text-white"
                          ></i
                          >Tambah Data SDM</b-button
                        >
                      </div>
                    </div>
                    <br />
                    <div class="card card-custom">
                      <div class="card-body">
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-1
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Dinas
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-1">
                              <div v-if="kelembagaan.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="lembaga in kelembagaan"
                                      :key="lembaga"
                                    >
                                      <td>{{ lembaga.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperlembaga(lembaga.id)"
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(lembaga.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  v-model="pm.p2_kadis_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-1" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kadis_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kadis_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kadis_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kadis_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kadis_serppb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 20px;
                                padding: 5px 0px 5px 0px;
                                min-height: 25px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-2
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Bidang Perizinan Berusaha/Pejabat
                              Fungsional Madya Bidang Perizinan Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-2">
                              <div v-if="perizinanberusaha.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="pb in perizinanberusaha"
                                      :key="pb"
                                    >
                                      <td>{{ pb.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperlembaga(pb.id)"
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(pb.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  v-model="pm.p2_kabidpb_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-2" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kabidpb_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kabidpb_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kabidpb_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kabidpb_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kabidpb_serppb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 20px;
                                padding: 5px 0px 5px 0px;
                                min-height: 25px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-3
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Seksi Bidang Perizinan Berusaha/Pejabat
                              Fungsional Muda Bidang Perizinan Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-3">
                              <div v-if="kepalaseksi.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="ks in kepalaseksi" :key="ks">
                                      <td>{{ ks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperlembaga(ks.id)"
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(ks.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  v-model="pm.p2_kasiepb_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-3" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kasiepb_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kasiepb_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kasiepb_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kasiepb_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kasiepb_serppb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-4
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Front Office</em> Bidang Perizinan
                              Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-4">
                              <div v-if="stafffoizin.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="sfoz in stafffoizin" :key="sfoz">
                                      <td>{{ sfoz.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperlembaga(sfoz.id)"
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(sfoz.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  v-model="pm.p2_foffpb_jumlah"
                                  style="text-align: center"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-4" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_foffpb_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_foffpb_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_foffpb_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_foffpb_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    v-model="pm.p2_foffpb_serppb"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-5
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Back Office</em> Bidang Perizinan
                              Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-5">
                              <div v-if="staffboizin.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="sboz in staffboizin" :key="sboz">
                                      <td>{{ sboz.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperlembaga(sboz.id)"
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(sboz.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  id="input-small"
                                  style="text-align: center"
                                  v-model="pm.p2_boffpb_jumlah"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-5" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    v-model="pm.p2_boffpb_serbpb"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="pm.p2_boffpb_pengalaman"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="pm.p2_boffpb_seross"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="pm.p2_boffpb_serbha"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="pm.p2_boffpb_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-6
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Helpdesk</em> Bidang Perizinan Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-6">
                              <div v-if="staffhd.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="staffhd in staffhd"
                                      :key="staffhd"
                                    >
                                      <td>{{ staffhd.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperlembaga(staffhd.id)"
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(staffhd.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  id="input-small"
                                  disabled
                                  v-model="pm.p2_hdeskpb_jumlah"
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-6" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_hdeskpb_serbpb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_hdeskpb_pengalaman"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_hdeskpb_seross"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_hdeskpb_serbha"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_hdeskpb_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 20px;
                                padding: 5px 0px 5px 0px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-7
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Bidang Pengendalian Pelaksanaan/Pejabat
                              Fungsional Madya Bidang Pengendalian Pelaksanaan
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-7">
                              <div v-if="kepaladalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="kepaladalaks in kepaladalaks"
                                      :key="kepaladalaks"
                                    >
                                      <td>{{ kepaladalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="
                                            getperlembaga(kepaladalaks.id)
                                          "
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(kepaladalaks.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  type="number"
                                  v-model="pm.p2_kabiddal_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-7" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    v-model="pm.p2_kabiddal_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    v-model="pm.p2_kabiddal_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    v-model="pm.p2_kabiddal_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_kabiddal_serbha"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_kabiddal_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 25px;
                                padding: 5px 0px 5px 0px;
                                min-height: 20px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                                padding: auto;
                              "
                              v-b-toggle.collapse-8
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Seksi Bidang Pengendalian
                              Pelaksanaan/Pejabat Fungsional Muda Bidang
                              Pengendalian Pelaksanaan
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-8">
                              <div v-if="ksdalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="ksdalaks in ksdalaks"
                                      :key="ksdalaks"
                                    >
                                      <td>{{ ksdalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperlembaga(ksdalaks.id)"
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(ksdalaks.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="pm.p2_kasiedal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-8" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_kasiedal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_kasiedal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_kasiedal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_kasiedal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_kasiedal_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-9
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Front Office</em> Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-9">
                              <div v-if="sfodalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="sfodalaks in sfodalaks"
                                      :key="sfodalaks"
                                    >
                                      <td>{{ sfodalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperlembaga(sfodalaks.id)"
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(sfodalaks.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="pm.p2_foffdal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-9" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_foffdal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_foffdal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_foffdal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_foffdal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_foffdal_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-10
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Back Office</em> Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-10">
                              <div v-if="sbodalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="sbodalaks in sbodalaks"
                                      :key="sbodalaks"
                                    >
                                      <td>{{ sbodalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperlembaga(sbodalaks.id)"
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(sbodalaks.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="pm.p2_boffdal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-10" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_boffdal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_boffdal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_boffdal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_boffdal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_boffdal_serppb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-11
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Helpdesk</em> Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-11">
                              <div v-if="shddalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Ubah</th>
                                      <th>Hapus</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="shddalaks in shddalaks"
                                      :key="shddalaks"
                                    >
                                      <td>{{ shddalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperlembaga(shddalaks.id)"
                                        >
                                          <i class="flaticon-edit"></i>Ubah
                                        </b-button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          @click="hapussdm(shddalaks.id)"
                                          class="btn btn-light-danger btn-sm font-weight-bold"
                                          data-dismiss="modal"
                                        >
                                          <i class="flaticon2-trash"></i>Hapus
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="pm.p2_hdeskdal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-11" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_hdeskdal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_hdeskdal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_hdeskdal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_hdeskdal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_hdeskdal_serppb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <div v-if="pm.catatan_2_surveyor != null">
                          <div class="card text-white bg-primary mb-3">
                            <!-- <div class="card-header">Header</div> -->
                            <div class="card-body">
                              <h5 class="card-title">
                                <i
                                  class="fa fa-info-circle"
                                  style="color: white"
                                  aria-hidden="true"
                                ></i>
                                Catatan
                              </h5>
                              <hr />
                              <div
                                class="card-text"
                                v-html="pm.catatan_2_surveyor"
                              ></div>
                              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                            </div>
                          </div>
                        </div>
                        <!-- <button type="button" style="float: right;" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Save</button> -->
                        <!--end: Datatable-->
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="pm.catatan_3_surveyor != null">
                      <b-alert show variant="danger"
                        >Terdapat catatan, silahkan lihat catatan
                        dibawah!</b-alert
                      >
                    </div>
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Sarpras PTSP
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b
                                >Sarana dan prasarana utama sistem Pelayanan
                                Perizinan Berusaha</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">1</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kelayakan ruang
                              <i style="font-size: 14px; color: black">
                                <em>front office</em>
                              </i>
                              ?
                            </td>
                            <td colspan="4">
                              Penilaian kecukupan dan kelayakan ruang
                              <i style="font-size: 14px; color: black">
                                <em>front office</em>
                              </i>
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia ruangan/loket/meja dan petugas
                                  tersendiri.
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a1_file1"
                                v-b-tooltip.hover
                                title="Foto ruangan front office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a1_file1 != null"
                                @click="unduhFile(pm.p3a1_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Ruang
                                  <i style="font-size: 14px; color: black">
                                    front office
                                  </i>
                                  memanfaatkan teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a1_file2"
                                v-b-tooltip.hover
                                title="Foto pemanfaatan IT di ruang front office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a1_file2 != null"
                                @click="unduhFile(pm.p3a1_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Ruang
                                  <i style="font-size: 14px; color: black"
                                    >front office</i
                                  >
                                  terawat dan memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a1_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a1_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a1_file3"
                                v-b-tooltip.hover
                                title="Foto ruangan front office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a1_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a1_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a1_file3 != null"
                                @click="unduhFile(pm.p3a1_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.<br />
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">2</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang
                              <i style="font-size: 14px; color: black"
                                >front office</i
                              >
                              ?
                            </td>
                            <td colspan="4">
                              Penilaian kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang
                              <i style="font-size: 14px; color: black"
                                >front office</i
                              >
                              dilakukan dengan ketentuan
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <i style="font-size: 14px; color: black"
                                    >front office</i
                                  >
                                  memiliki jumlah yang memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a2_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a2_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a2_file1"
                                v-b-tooltip.hover
                                title="Foto saran pendukung ruangan front office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a2_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a2_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a2_file1 != null"
                                @click="unduhFile(pm.p3a2_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <i style="font-size: 14px; color: black"
                                    >front office</i
                                  >
                                  memanfaatkan teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a2_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a2_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a2_file2"
                                v-b-tooltip.hover
                                title="Foto pemanfaatan IT di ruang front office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a2_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a2_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a2_file2 != null"
                                @click="unduhFile(pm.p3a2_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <i style="font-size: 14px; color: black"
                                    >front office</i
                                  >
                                  berfungsi dan layak digunakan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a2_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a2_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a2_file3"
                                v-b-tooltip.hover
                                title="Foto saran pendukung ruangan front office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a2_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a2_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a2_file3 != null"
                                @click="unduhFile(pm.p3a2_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">3</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kelayakan ruang
                              <i style="font-size: 14px; color: black"
                                >back office</i
                              >?
                            </td>
                            <td colspan="4">
                              Penilaian kecukupan dan kelayakan ruang
                              <i style="font-size: 14px; color: black"
                                >back office</i
                              >
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia ruang dan petugas tersendiri untuk
                                  <i style="font-size: 14px; color: black"
                                    >back office</i
                                  >
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a3_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a3_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a3_file1"
                                v-b-tooltip.hover
                                title="Foto ruangan back office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a3_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a3_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a3_file1 != null"
                                @click="unduhFile(pm.p3a3_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Ruang
                                  <i style="font-size: 14px; color: black"
                                    >back office</i
                                  >
                                  memanfaatkan teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a3_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a3_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a3_file2"
                                v-b-tooltip.hover
                                title="Foto pemanfaatan IT di ruang back office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a3_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a3_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a3_file2 != null"
                                @click="unduhFile(pm.p3a3_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Ruang
                                  <i style="font-size: 14px; color: black"
                                    >back office</i
                                  >
                                  terawat dan memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a3_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a3_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a3_file3"
                                v-b-tooltip.hover
                                title="Foto ruangan back office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a3_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a3_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a3_file3 != null"
                                @click="unduhFile(pm.p3a3_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">4</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang
                              <i style="font-size: 14px; color: black"
                                >back office</i
                              >?
                            </td>
                            <td colspan="4">
                              Penilaian kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang
                              <i style="font-size: 14px; color: black"
                                >back office</i
                              >
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <i style="font-size: 14px; color: black"
                                    >back office</i
                                  >
                                  memiliki jumlah yang memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a4_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a4_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a4_file1"
                                v-b-tooltip.hover
                                title="Foto sarana pendukung ruangan back office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a4_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a4_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a4_file1 != null"
                                @click="unduhFile(pm.p3a4_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <i style="font-size: 14px; color: black"
                                    >back office</i
                                  >
                                  memanfaatkan teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a4_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a4_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a4_file2"
                                v-b-tooltip.hover
                                title="Foto pemanfaatan IT di ruang back office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a4_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a4_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a4_file2 != null"
                                @click="unduhFile(pm.p3a4_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <i style="font-size: 14px; color: black"
                                    >back office</i
                                  >
                                  berfungsi dan layak digunakan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3a4_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3a4_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3a4_file3"
                                v-b-tooltip.hover
                                title="Foto sarana pendukung ruangan back office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3a4_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3a4_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3a4_file3 != null"
                                @click="unduhFile(pm.p3a4_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b>Sarana dan prasarana pendukung</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7" style="text-align: justify">
                              Bagaimana kecukupan dan kelayakan ruang pendukung
                              (ruang rapat, ruang rapat prioritas, ruang
                              pelayanan mandiri)?
                            </td>
                            <td colspan="4">
                              Penilaian kecukupan dan kelayakan ruang pendukung
                              (ruang rapat, ruang pelayanan prioritas, ruang
                              pelayanan mandiri) dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Tersedia ruang rapat</div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b1_file1"
                                v-b-tooltip.hover
                                title="Foto ruang rapat"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b1_file1 != null"
                                @click="unduhFile(pm.p3b1_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia ruang pelayanan prioritas
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b1_file2"
                                v-b-tooltip.hover
                                title="Foto ruang pelayanan prioritas"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b1_file2 != null"
                                @click="unduhFile(pm.p3b1_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia ruang pelayanan mandiri
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b1_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b1_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b1_file3"
                                v-b-tooltip.hover
                                title="Foto ruang pelayanan mandiri"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b1_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b1_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b1_file3 != null"
                                @click="unduhFile(pm.p3b1_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia sarana teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b1_file4"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b1_file4"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b1_file4"
                                v-b-tooltip.hover
                                title="Foto sarana teknologi informasi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b1_file4 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b1_file4')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b1_file4 != null"
                                @click="unduhFile(pm.p3b1_file4)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Ruangan pendukung terawat dan memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b1_file5"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b1_file5"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b1_file5"
                                v-b-tooltip.hover
                                title="Foto ruangan pendukung"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b1_file5 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b1_file5')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b1_file5 != null"
                                @click="unduhFile(pm.p3b1_file5)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="5">2</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kelayakan ruang pendukung
                              (ruang rapat, ruang rapat prioritas, ruang
                              pelayanan mandiri)?
                            </td>
                            <td colspan="4">
                              Penilaian kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang pendukung (meja,
                              kursi, komputer, <em>printer</em>, pendukung yang
                              relevan) dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  pendukung memiliki jumlah yang memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b2_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b2_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b2_file1"
                                v-b-tooltip.hover
                                title="Foto sarana pendukung ruangan front office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b2_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b2_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b2_file1 != null"
                                @click="unduhFile(pm.p3b2_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  pendukung memanfaatkan teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b2_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b2_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b2_file2"
                                v-b-tooltip.hover
                                title="Foto pemanfaatan IT di ruang front office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b2_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b2_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b2_file2 != null"
                                @click="unduhFile(pm.p3b2_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  pendukung berfungsi dan layak digunakan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b2_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b2_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b2_file3"
                                v-b-tooltip.hover
                                title="Foto sarana pendukung ruangan front office"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b2_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b2_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b2_file3 != null"
                                @click="unduhFile(pm.p3b2_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Bagaimana kecukupan dan kelayakan sarana informasi
                              (Identitas kantor, petunjuk arah, informasi
                              pelayanan)?
                            </td>
                            <td colspan="4">
                              Penilaian sarana informasi (Identitas kantor,
                              petunjuk arah dan informasi pelayanan) dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia identitas kantor
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b3_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b3_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b3_file1"
                                v-b-tooltip.hover
                                title="Foto identitas kantor"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b3_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b3_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b3_file1 != null"
                                @click="unduhFile(pm.p3b3_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia petunjuk arah dan ruangan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b3_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b3_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b3_file2"
                                v-b-tooltip.hover
                                title="Foto petunjuk arah dan ruangan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b3_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b3_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b3_file2 != null"
                                @click="unduhFile(pm.p3b3_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia informasi jenis-jenis pelayanan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b3_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b3_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b3_file3"
                                v-b-tooltip.hover
                                title="Foto informasi jenis-jenis pelayanan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b3_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b3_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b3_file3 != null"
                                @click="unduhFile(pm.p3b3_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia sarana teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b3_file4"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b3_file4"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b3_file4"
                                v-b-tooltip.hover
                                title="Foto sarana teknologi informasi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b3_file4 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b3_file4')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b3_file4 != null"
                                @click="unduhFile(pm.p3b3_file4)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Sarana informasi terawat dan memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp3b3_file5"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p3b3_file5"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp3b3_file5"
                                v-b-tooltip.hover
                                title="Foto sarana informasi yang terawat dan memadai"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p3b3_file5 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p3b3_file5')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p3b3_file5 != null"
                                @click="unduhFile(pm.p3b3_file5)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <div class="row">
                            <div class="col-6">

                            </div>
                            <div class="col-6">
                                <button type="button" @click="getjumlah('3')" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase"><i class="fas fa-save"></i>Simpan Form</button>
                            </div>
                        </div> -->
                      <br />
                      <div v-if="pm.catatan_3_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_3_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="pm.catatan_4_surveyor != null">
                      <b-alert show variant="danger"
                        >Terdapat catatan, silahkan lihat catatan
                        dibawah!</b-alert
                      >
                    </div>
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Implementasi OSS PTSP
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b
                                >Ketersediaan Standar Operasional
                                Prosedur(SOP)</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah tersedia SOP implementasi OSS?
                            </td>
                            <td colspan="4">
                              Penilaian ketersediaan SOP implementasi OSS
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP ditetapkan melalui Peraturan Kepala Daerah
                                  atau Peraturan Kepala DPMPTSP
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4a1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4a1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4a1_file1"
                                v-b-tooltip.hover
                                title="Dokumen SOP Implementasi OSS"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4a1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4a1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4a1_file1 != null"
                                @click="unduhFile(pm.p4a1_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP merupakan ketentuan mengenai tata kelola
                                  penggunaan sistem OSS dalam pelayanan dan
                                  pengawasan perizinan berusaha sesuai
                                  kewenangannya
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4a1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4a1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4a1_file2"
                                v-b-tooltip.hover
                                title="Dokumen SOP Implementasi OSS"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4a1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4a1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4a1_file2 != null"
                                @click="unduhFile(pm.p4a1_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP secara jelas mengatur peran OPD Teknis
                                  sebagai verifikator dan pengawas perizinan
                                  berusaha dan DPMPTSP sebagai pemberi
                                  persetujuan dan koordinator pengawasan
                                  perizinan berusaha
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4a1_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4a1_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4a1_file3"
                                v-b-tooltip.hover
                                title="Dokumen SOP Implementasi OSS"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4a1_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4a1_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4a1_file3 != null"
                                @click="unduhFile(pm.p4a1_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4a1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP mencakup seluruh bidang usaha Klasifikasi
                                  Baku Lapangan Usaha Indonesia (KBLI) yang
                                  menjadi kewenangannya
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4a1_file4"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4a1_file4"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4a1_file4"
                                v-b-tooltip.hover
                                title="Dokumen SOP Implementasi OSS"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4a1_file4 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4a1_file4')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4a1_file4 != null"
                                @click="unduhFile(pm.p4a1_file4)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4a1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP dapat diakses oleh publik secara digital
                                  atau konvensional
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4a1_file5"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4a1_file5"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4a1_file5"
                                v-b-tooltip.hover
                                title="Bukti akses SOP (Foto, URL, dll)"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4a1_file5 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4a1_file5')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4a1_file5 != null"
                                @click="unduhFile(pm.p4a1_file5)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kelima ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b>Implementasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan sosialisasi SOP implementasi
                              OSS ?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya kegiatan sosialisasi SOP
                              implementasi OSS dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sosialisasi SOP implementasi OSS dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b1_file1"
                                v-b-tooltip.hover
                                title="Dokumen undangan/informasi kegiatan sosialisasi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b1_file1 != null"
                                @click="unduhFile(pm.p4b1_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b1_file2"
                                v-b-tooltip.hover
                                title="Dokumen undangan/informasi kegiatan sosialisasi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b1_file2 != null"
                                @click="unduhFile(pm.p4b1_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b1_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b1_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b1_file3"
                                v-b-tooltip.hover
                                title="Daftar hadir"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b1_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b1_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b1_file3 != null"
                                @click="unduhFile(pm.p4b1_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia notula sosialisasi SOP implementasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b1_file4"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b1_file4"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b1_file4"
                                v-b-tooltip.hover
                                title="Notula"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b1_file4 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b1_file4')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b1_file4 != null"
                                @click="unduhFile(pm.p4b1_file4)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b1_file5"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b1_file5"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b1_file5"
                                v-b-tooltip.hover
                                title="Foto kegiatan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b1_file5 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b1_file5')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b1_file5 != null"
                                @click="unduhFile(pm.p4b1_file5)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">2</td>
                            <td rowspan="5">
                              Apakah ada kegiatan terjadwal pendampingan
                              implementasi OSS?
                            </td>
                            <td colspan="4">
                              Penilaian kecukupan kegiatan pendampingan
                              implementasi OSS dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan pendampingan implementasi OSS
                                  dilakukan sekurang-kurangnya 1 kali dalam 1
                                  bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b2_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b2_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b2_file1"
                                v-b-tooltip.hover
                                title="Bukti Jadwal pendampingan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b2_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b2_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b2_file1 != null"
                                @click="unduhFile(pm.p4b2_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia surat penugasan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b2_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b2_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b2_file2"
                                v-b-tooltip.hover
                                title="Surat Tugas pendampingan"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b2_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b2_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b2_file2 != null"
                                @click="unduhFile(pm.p4b2_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia dokumentasi kegiatan dalam bentuk
                                  laporan atau foto kegiatan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b2_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b2_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b2_file3"
                                v-b-tooltip.hover
                                title="Dokumentasi pendampingan (Foro/laporan)"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b2_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b2_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b2_file3 != null"
                                @click="unduhFile(pm.p4b2_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, terjadwal” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak terjadwal” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">3</td>
                            <td rowspan="2">
                              Bagaimana kinerja/hasil implementasi OSS?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  @change="onFileChangedp4b3_file1_radio"
                                  v-model="pm.p4b3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="pm.p4b3_nilai"
                                  value="25"
                                  >Semua Perizinan Berusaha menggunakan
                                  OSS</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp4b3_file1_radio"
                                  v-model="pm.p4b3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="pm.p4b3_nilai"
                                  value="10"
                                  >Sebagian Perizinan Berusaha menggunakan
                                  OSS</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b3_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b3_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b3_file1"
                                v-b-tooltip.hover
                                title="Dokumen permohonan Izin PM yang masuk dan tangkapan layar bukti pelayanan di OSS"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b3_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b3_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b3_file1 != null"
                                @click="unduhFile(pm.p4b3_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penilaian kinerja/hasil implementasi OSS
                                    dilakukan dengan ketentuan dapat menunjukkan
                                    permohonan pelayanan perizinan dalam sistem
                                    OSS. Penetapan bobot penilaian:
                                  </p>
                                  <ol
                                    style="
                                      margin-left: 11px;
                                      text-align: justify;
                                    "
                                  >
                                    <li>
                                      Nilai &ldquo;Semua perizinan menggunakan
                                      OSS&rdquo; apabila seluruh permohonan
                                      perizinan berusaha yang masuk sesuai
                                      lampiran PP No. 5 Tahun 2021 yang menjadi
                                      kewenangannya dapat ditunjukkan di sistem
                                      OSS.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Sebagian perizinan
                                      menggunakan OSS&rdquo; apabila sebagian
                                      permohonan perizinan berusaha yang masuk
                                      sesuai lampiran PP No. 5 Tahun 2021 yang
                                      menjadi kewenangannya dapat ditunjukkan di
                                      sistem OSS.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">4</td>
                            <td rowspan="5" style="texta-align: justify">
                              Apakah ada verifikasi perizinan berusaha dengan
                              risiko Menengah Tinggi (MT) dan Tinggi (T)?
                            </td>
                            <td colspan="4">
                              Penilaian adanya verifikasi perizinan berusaha
                              dengan risiko Menengah Tinggi (MT) dan Tinggi (T)
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Pemerintah daerah melakukan verifikasi
                                  terhadap seluruh perizinan berusaha dengan
                                  risiko Menengah Tinggi (MT) dan Tinggi (T)
                                  yang diterima dari pelaku usaha
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b4_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b4_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b4_file1"
                                v-b-tooltip.hover
                                title="Daftar permohonan Perizinan PB yang masuk (MT dan T)"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b4_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b4_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b4_file1 != null"
                                @click="unduhFile(pm.p4b4_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tidak ada dokumen terkait permohonan perizinan
                                  berusaha secara fisik yang wajib diserahkan
                                  oleh pelaku usaha kepada PTSP
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp4b4_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p4b4_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp4b4_file2"
                                v-b-tooltip.hover
                                title="Surat Pernyataan Kadis DPMPTSP"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p4b4_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p4b4_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p4b4_file2 != null"
                                @click="unduhFile(pm.p4b4_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Sebagian” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <div class="row">
                            <div class="col-6">

                            </div>
                            <div class="col-6">
                                <button type="button" @click="getjumlah('4')" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase"><i class="fas fa-save"></i>Simpan Form</button>
                            </div>
                        </div> -->
                      <br />
                      <div v-if="pm.catatan_4_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_4_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Step 5-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="pm.catatan_5_surveyor != null">
                      <b-alert show variant="danger"
                        >Terdapat catatan, silahkan lihat catatan
                        dibawah!</b-alert
                      >
                    </div>
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Keluaran PTSP
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b>Realisasi target investasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">1</td>
                            <td rowspan="3">
                              Bagaimana tingkat realisasi target investasi?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  @change="onFileChangedp5a1_file1_radio"
                                  v-model="pm.p5a1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a1_nilai"
                                  value="25"
                                  >Terlampaui atau tercapai</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp5a1_file1_radio"
                                  v-model="pm.p5a1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a1_nilai"
                                  value="10"
                                  >Tidak tercapai</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp5a1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p5a1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp5a1_file1"
                                v-b-tooltip.hover
                                title="Dokumen target investasi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p5a1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p5a1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p5a1_file1 != null"
                                @click="unduhFile(pm.p5a1_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr colspan="4">
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp5a1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p5a1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp5a1_file2"
                                v-b-tooltip.hover
                                title="Dokumen realisasi investasi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p5a1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p5a1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p5a1_file2 != null"
                                @click="unduhFile(pm.p5a1_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penilaian tingkat realisasi target investasi
                                    dilakukan dengan ketentuan:
                                  </p>
                                  <ol style="margin-left: 11px">
                                    <li>
                                      Memiliki dan dapat menunjukkan data target
                                      investasi.
                                    </li>
                                    <li>
                                      Memiliki dan dapat menunjukkan data
                                      tingkat realisasi investasi.
                                    </li>
                                  </ol>
                                  <p>Penetapan bobot penilaian:</p>
                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Terlampaui atau
                                      tercapai&rdquo; apabila tingkat realisasi
                                      investasi melebihi atau mencapai target
                                      yang ditetapkan.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak tercapai&rdquo; apabila
                                      tingkat realisasi investasi tidak mencapai
                                      target yang ditetapkan.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Bagaimana tingkat penyerapan dan pemanfaatan Dana
                              Alokasi Khusus (DAK)?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  @change="onFileChangedp5a2_file1_radio"
                                  v-model="pm.p5a2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a2_nilai"
                                  value="25"
                                  >80 – 100%</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp5a2_file1_radio"
                                  v-model="pm.p5a2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a2_nilai"
                                  value="20"
                                  >60 – 79,99%</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp5a2_file1_radio"
                                  v-model="pm.p5a2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a2_nilai"
                                  value="10"
                                  >40 – 59,99%</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp5a2_file1_radio"
                                  v-model="pm.p5a2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a2_nilai"
                                  value="5"
                                  >di bawah 40%</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp5a2_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p5a2_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp5a2_file1"
                                v-b-tooltip.hover
                                title="Dokumen alokasi DAK dan realisasinya"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p5a2_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p5a2_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p5a2_file1 != null"
                                @click="unduhFile(pm.p5a2_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="text-align: justify; color: white">
                                    Penilaian tingkat penyerapan dan pemanfaatan
                                    Dana Alokasi Khusus (DAK) dilakukan dengan
                                    ketentuan memiliki dan dapat menunjukkan
                                    dokumen penyerapan dan pemanfaatan DAK.
                                    Penetapan bobot penilaian:
                                  </p>
                                  <ol
                                    style="
                                      text-align: justify;
                                      margin-left: 11px;
                                      color: white;
                                    "
                                  >
                                    <li>
                                      Nilai &ldquo;80-100%&rdquo; apabila
                                      penyerapan dan pemanfaatan DAK
                                      sekurang-kurangnya sebesar 80% dari yang
                                      ditetapkan.
                                    </li>
                                    <li>
                                      Nilai &ldquo;60 &ndash; 79,99%&rdquo;
                                      apabila penyerapan dan pemanfaatan DAK
                                      sekurang-kurangnya sebesar 60% dan
                                      sebanyak-banyaknya sebesar 79,99% dari
                                      yang ditetapkan.
                                    </li>
                                    <li>
                                      Nilai &ldquo;40 &ndash; 59,99%&rdquo;
                                      apabila penyerapan dan pemanfaatan DAK
                                      sekurang-kurangnya sebesar 40%
                                      sebanyak-banyaknya sebesar 59,99% dari
                                      yang ditetapkan.
                                    </li>
                                    <li>
                                      Nilai &ldquo;di bawah 40%&rdquo; apabila
                                      penyerapan dan pemanfaatan DAK
                                      sebanyak-banyaknya sebesar 40% dari yang
                                      ditetapkan.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b>Realisasi target penyerapan tenaga kerja</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">1</td>
                            <td rowspan="3">
                              Bagaimana realisasi target penyerapan tenaga
                              kerja?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  @change="onFileChangedp5b1_file1_radio"
                                  v-model="pm.p5b1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="some-radios"
                                  value="25"
                                  >Terlampaui atau tercapai</b-form-radio
                                >
                                <b-form-radio
                                  @change="onFileChangedp5b1_file1_radio"
                                  v-model="pm.p5b1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="some-radios"
                                  value="10"
                                  >Tidak tercapai</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <input
                                @change="onFileChangedp5b1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p5b1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp5b1_file1"
                                v-b-tooltip.hover
                                title="Dokumen target penyerapan TK"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p5b1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p5b1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p5b1_file1 != null"
                                @click="unduhFile(pm.p5b1_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr colspan="4">
                            <td>
                              <input
                                @change="onFileChangedp5b1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p5b1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp5b1_file2"
                                v-b-tooltip.hover
                                title="Dokumen realisasi penyerapan TK"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p5b1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p5b1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p5b1_file2 != null"
                                @click="unduhFile(pm.p5b1_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penilaian tingkat realisasi target investasi
                                    dilakukan dengan ketentuan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Memiliki dan dapat menunjukkan data target
                                      tenaga kerja.
                                    </li>
                                    <li>
                                      Memiliki dan dapat menunjukkan data
                                      tingkat realisasi penyerapan tenaga kerja.
                                    </li>
                                  </ol>

                                  <p>Penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Terlampaui atau
                                      tercapai&rdquo; apabila tingkat realisasi
                                      investasi melebihi atau mencapai target
                                      yang ditetapkan.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak tercapai&rdquo; apabila
                                      tingkat realisasi investasi tidak mencapai
                                      target yang ditetapkan.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="6" style="background-color: #ebedf3">
                              <b>Fasilitasi terhadap Usaha Mikro Kecil (UMK)</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="6">1</td>
                            <td rowspan="6">
                              Apakah ada fasilitasi kemitraan dengan pelaku UMK
                              daerah?
                            </td>
                            <td colspan="4">
                              Penilaian fasilitasi kemitraan dengan pelaku UMK
                              daerah dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p5c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Fasilitasi kemitraan dengan pelaku UMK daerah
                                  dilakukan sekurang-kurangnya 1 kali dalam 3
                                  bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp5c1_file1"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p5c1_file1"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp5c1_file1"
                                v-b-tooltip.hover
                                title="Surat tugas Sosialisasi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p5c1_file1 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p5c1_file1')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p5c1_file1 != null"
                                @click="unduhFile(pm.p5c1_file1)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p5c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat tugas fasilitasi kemitraan
                                  dengan pelaku UMK daerah
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp5c1_file2"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p5c1_file2"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp5c1_file2"
                                v-b-tooltip.hover
                                title="Surat tugas Sosialisasi"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p5c1_file2 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p5c1_file2')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p5c1_file2 != null"
                                @click="unduhFile(pm.p5c1_file2)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p5c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Fasilitasi kemitraan dengan pelaku UMK daerah
                                  melibatkan pihak terkait
                                  (Perbankan/Asosiasi/Pelaku usaha
                                  Menengah-Besar)
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp5c1_file3"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p5c1_file3"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp5c1_file3"
                                v-b-tooltip.hover
                                title="Daftar hadir Bukti keterlibatan pihak terkaiti"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p5c1_file3 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p5c1_file3')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p5c1_file3 != null"
                                @click="unduhFile(pm.p5c1_file3)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p5c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia laporan atau dokumentasi kegiatan
                                  fasilitasi kemitraan dalam bentuk foto/video
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                :key="fileInputKey"
                                @change="onFileChangedp5c1_file4"
                                accept=".pdf"
                                type="file"
                                id="file"
                                ref="p5c1_file4"
                                class="custom-file-input"
                                hidden
                              />
                              <button
                                @click="previewFilesp5c1_file4"
                                v-b-tooltip.hover
                                title="Laporan kegiatan fasilitasi (BA dan Laporan)"
                                type="button"
                                style="
                                  background-color: #030a8c;
                                  border-color: #030a8c;
                                "
                                size="sm"
                                class="btn btn-primary text-uppercase btn-sm"
                              >
                                <i class="flaticon-upload"></i> Unggah File
                              </button>
                            </td>
                            <td>
                              <a
                                v-if="pm.p5c1_file4 != null"
                                type="button"
                                data-toggle="Hapus"
                                data-placement="top"
                                title="Hapus"
                                @click="deleteFile('p5c1_file4')"
                              >
                                <i
                                  style="color: red"
                                  class="flaticon2-trash"
                                ></i>
                              </a>
                            </td>
                            <td>
                              <button
                                v-if="pm.p5c1_file4 != null"
                                @click="unduhFile(pm.p5c1_file4)"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila keempat
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <div class="row">
                                <div class="col-6">

                                </div>
                                <div class="col-6">
                                    <button type="button" @click="getjumlah('5')" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase"><i class="fas fa-save"></i>Simpan Form</button>
                                </div>
                            </div> -->
                      <br />
                      <div v-if="pm.catatan_5_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_5_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Sebelumnya
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Selesai
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->

                <!-- Modal tambah sdm -->
                <b-modal
                  id="modal-prevent-closing"
                  ref="modal"
                  size="lg"
                  scrollable
                  title="Tambah Aspek Sumber Daya Manusia"
                  @show="resetModal"
                  @hidden="handleOk"
                  @ok="handleOk"
                  @cancel="handleOk"
                >
                  <form
                    ref="form"
                    @submit.stop.prevent="handleSubmit"
                    data-vv-scope="form-1"
                  >
                    <div class="form-group row">
                      <label
                        class="col-lg-4 col-form-label"
                        invalid-feedback="Name is required"
                        :state="nameState"
                        >Nama Lengkap SDM
                        <span style="color: red">*</span></label
                      >
                      <div class="col-lg-8">
                        <b-form-input
                          type="text"
                          v-validate="'required'"
                          name="Nama"
                          v-model="nama_sdm"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors.has('form-1.Nama'),
                          }"
                          id="name"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.Nama')"
                        >
                          {{ errors.first("form-1.Nama") }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        class="col-lg-4 col-form-label"
                        invalid-feedback="NIP is required"
                        :state="nipState"
                        >NIP <span style="color: red">*</span></label
                      >
                      <div class="col-lg-8">
                        <b-form-input
                          type="number"
                          v-model="nip"
                          placeholder="Jika tidak memiliki NIP harap isi dengan angka 01, 02, dst"
                          v-validate="'required'"
                          name="NIP"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors.has('form-1.NIP'),
                          }"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.NIP')"
                        >
                          {{ errors.first("form-1.NIP") }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4" invalid-feedback="Bagian is required"
                        >Bagian <span style="color: red">*</span></label
                      >
                      <div class="col-8">
                        <b-form-select
                          v-model="jabatan"
                          required
                          :options="bagian"
                          v-validate="'required'"
                          name="Bagian"
                          :class="{ 'is-invalid': errors.has('form-1.Bagian') }"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.Bagian')"
                        >
                          {{ errors.first("form-1.Bagian") }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        class="col-lg-4 col-form-label"
                        invalid-feedback="Pendidikan is required"
                        >Pendidikan Terakhir, Bidang Ilmu
                        <span style="color: red">*</span></label
                      >
                      <div class="col-lg-8">
                        <b-form-input
                          type="text"
                          v-model="pendidikan"
                          placeholder="S1, Teknik Informatika"
                          required
                          v-validate="'required'"
                          name="Pendidikan"
                          :class="{
                            'is-invalid': errors.has('form-1.Pendidikan'),
                          }"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.Pendidikan')"
                        >
                          {{ errors.first("form-1.Pendidikan") }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        class="col-lg-4 col-form-label"
                        invalid-feedback="Tahun is required"
                        >Lama Bertugas di Bidang Terkait (Tahun)
                        <span style="color: red">*</span></label
                      >
                      <div class="col-lg-8">
                        <b-form-input
                          type="number"
                          v-model="lama_bertugas"
                          required
                          min="0"
                          v-validate="'required'"
                          name="Lama Bertugas"
                          :class="{
                            'is-invalid': errors.has('form-1.Lama Bertugas'),
                          }"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.Lama Bertugas')"
                        >
                          {{ errors.first("form-1.Lama Bertugas") }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >No. Sertifikat Bidang Perizinan Berusaha</label
                      >
                      <div class="col-lg-8">
                        <input
                          type="text"
                          v-model="ser_bpb_dasar"
                          placeholder="Masukkan nomor sertifikat tingkat dasar"
                          class="form-control"
                        />
                        <br />
                        <input
                          type="text"
                          :disabled="
                            ser_bpb_dasar == null || ser_bpb_dasar == ''
                          "
                          v-model="ser_bpb_lanjutan"
                          placeholder="Masukkan nomor sertifikat tingkat lanjutan"
                          class="form-control"
                        />
                        <br />
                        <input
                          type="text"
                          :disabled="
                            ser_bpb_dasar == null || ser_bpb_dasar == ''
                          "
                          v-model="ser_bpb_sektoral"
                          placeholder="Masukkan nomor sertifikat tingkat sektoral"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >No. Sertifikat OSS</label
                      >
                      <div class="col-lg-8">
                        <input
                          type="text"
                          v-model="ser_oss"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >No. Sertifikat Bahasa Asing</label
                      >
                      <div class="col-lg-8">
                        <input
                          type="text"
                          v-model="ser_bha"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >No. Sertifikat Lain Pendukung Pelayanan Perizinan
                        Berusaha</label
                      >
                      <div class="col-lg-8">
                        <input
                          type="text"
                          v-model="ser_ppb"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >Format File Bukti</label
                      >
                      <div class="col-lg-8">
                        <a
                          :href="
                            this.url +
                            '/public/dokumen/pm_ptsp_2023/Lampiran_Bukti_SDM_Penkin 2023.docx'
                          "
                          target="_blank"
                          ><b-button variant="success">Download</b-button></a
                        >
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >Unggah File Bukti
                        <span style="color: red">*</span></label
                      >
                      <div class="col-lg-8">
                        <b-form-file
                          v-model="filename"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          v-validate="'required'"
                          name="File Bukti"
                          accept=".pdf"
                          id="fileSDM"
                          @change="validasiFileTambahSDM"
                          :class="{
                            'is-invalid': errors.has('form-1.File Bukti'),
                          }"
                        ></b-form-file>
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.File Bukti')"
                        >
                          {{ errors.first("form-1.File Bukti") }}
                        </span>
                        <br />
                        <span
                          >Maksimal ukuran file 500 KB dengan format file
                          *.pdf</span
                        >
                      </div>
                    </div>
                  </form>
                  <template #modal-footer>
                    <div class="w-100">
                      <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="
                          background-color: #030a8c;
                          border-color: #030a8c;
                          color: white;
                        "
                        @click="handleSubmit"
                      >
                        Kirim
                      </b-button>

                      <b-button
                        variant="danger"
                        size="sm"
                        class="float-right"
                        style="margin-right: 10px"
                        @click="$bvModal.hide('modal-prevent-closing')"
                      >
                        Batal
                      </b-button>
                    </div>
                  </template>
                </b-modal>

                <!-- modal edit sdm -->
                <div
                  class="modal fade"
                  id="editsdm"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLongTitle"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                          Perbaiki data SDM {{ getlembaga.nama_sdm }}
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Nama SDM <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              v-model="getlembaga.nama_sdm"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >NIP <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              type="number"
                              v-model="getlembaga.nip"
                              placeholder="Jika tidak memiliki NIP harap isi dengan angka 01, 02, dst"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-4"
                            >Bagian <span style="color: red">*</span></label
                          >
                          <div class="col-8">
                            <b-form-select
                              v-model="getlembaga.jabatan"
                              :options="bagian"
                            ></b-form-select>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Pendidikan Terakhir, Bidang Ilmu
                            <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              v-model="getlembaga.pendidikan"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Lama Bertugas di Bidang Terkait (Tahun)
                            <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              type="number"
                              v-model="getlembaga.lama_bertugas"
                              min="0"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat Bidang Perizinan Berusaha</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              v-model="getlembaga.ser_bpb_dasar"
                              placeholder="Masukkan nomor sertifikat tingkat dasar"
                              class="form-control"
                            />
                            <br />
                            <input
                              type="text"
                              :disabled="
                                getlembaga.ser_bpb_dasar == null ||
                                getlembaga.ser_bpb_dasar == ''
                              "
                              v-model="getlembaga.ser_bpb_lanjutan"
                              placeholder="Masukkan nomor sertifikat tingkat lanjutan"
                              class="form-control"
                            />
                            <br />
                            <input
                              type="text"
                              :disabled="
                                getlembaga.ser_bpb_dasar == null ||
                                getlembaga.ser_bpb_dasar == ''
                              "
                              v-model="getlembaga.ser_bpb_sektoral"
                              placeholder="Masukkan nomor sertifikat tingkat sektoral"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat OSS</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              v-model="getlembaga.ser_oss"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat Bahasa Asing</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              v-model="getlembaga.ser_bha"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat Lain Pendukung Pelayanan Perizinan
                            Berusaha</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              v-model="getlembaga.ser_ppb"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Format File Bukti</label
                          >
                          <div class="col-lg-8">
                            <a
                              :href="
                                this.url +
                                '/public/dokumen/pm_ptsp_2023/Lampiran_Bukti_SDM_Penkin 2023.docx'
                              "
                              target="_blank"
                              ><b-button variant="success"
                                >Download</b-button
                              ></a
                            >
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Unggah File Bukti
                            <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <b-form-file
                              v-model="getlembaga.filename"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              accept=".pdf"
                              ref="fileupload"
                            ></b-form-file>
                            <br />
                            <span
                              >Maksimal ukuran file 500 KB dengan format file
                              *.pdf</span
                            >
                            <br />
                            <a
                              v-if="getlembaga.filename != null"
                              :href="getlembaga.filename"
                              target="_blank"
                              ><i class="fa fa-download" aria-hidden="true" />
                              Unduh File</a
                            >
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Tutup
                          </button>
                          <button
                            type="button"
                            @click="editsdm(getlembaga.id)"
                            data-dismiss="modal"
                            class="btn btn-primary"
                          >
                            Simpan
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>
  
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-pm.scss";
</style>
  
  
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import axios from "axios";
import VueLoading from "vuejs-loading-plugin";
import Vue from "vue";

Vue.use(VueLoading);

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Loading", // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: "rgb(255,255,255)", // set custom background
  classes: ["myclass"], // array, object or string
});

export default {
  name: "Wizard-4",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Input PTSP Pemda" },
      //   { title: "PTSP" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", (e) => {
      this.getjumlah(e.currentStep);
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    this.loadData();
    this.getdata();
  },
  data() {
    return {
      bagian: [
        { value: "kadis", text: "Kepala Dinas" },
        {
          value: "kabidpb",
          text: "Kepala Bidang Perizinan Berusaha/Pejabat Fungsional Madya Bidang Perizinan Berusaha",
        },
        {
          value: "kasiepb",
          text: "Kepala Seksi Bidang Perizinan Berusaha/Pejabat Fungsional Muda Bidang Perizinan Berusaha",
        },
        {
          value: "foffpb",
          text: "Staf Front-office Bidang Perizinan Berusaha",
        },
        { value: "boffpb", text: "Staf Back-office Bidang Perizinan Berusaha" },
        { value: "hdeskpb", text: "Staf Help Desk Bidang Perizinan Berusaha" },
        {
          value: "kabiddal",
          text: "Kepala Bidang Pengendalian Pelaksanaan/Pejabat Fungsional Madya Bidang Pengendalian Pelaksanaan",
        },
        {
          value: "kasiedal",
          text: "Kepala Seksi Bidang Pengendalian Pelaksanaan/Pejabat Fungsional Muda Bidang Pengendalian Pelaksanaan",
        },
        { value: "foffdal", text: "Staf Front-office Bidang Dalaks" },
        { value: "boffdal", text: "Staf Back-office Bidang Dalaks" },
        { value: "hdeskdal", text: "Staf Help Desk Bidang Dalaks" },
      ],
      selectedFile: null,
      pm: [],
      sdm: [],
      kelembagaan: [],
      perizinanberusaha: [],
      kepalaseksi: [],
      stafffoizin: [],
      staffboizin: [],
      staffhd: [],
      kepaladalaks: [],
      ksdalaks: [],
      sfodalaks: [],
      sbodalaks: [],
      shddalaks: [],
      getlembaga: [],
      nama_sdm: "",
      nip: "",
      pendidikan: "",
      lama_bertugas: "",
      ser_bpb_dasar: "",
      ser_bpb_lanjutan: "",
      ser_bpb_sektoral: "",
      ser_oss: "",
      ser_bha: "",
      ser_ppb: "",
      jabatan: "",
      filename: "",
      fileInputKey: 0,
    };
  },
  props: {
    user: {
      type: Array,
      default: () => {
        return [];
      },
    },
    url: String,
    token: String,
    idpm: String,
    idptsp: String,
    idprofil: String,
    tahun: String,
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      this.getjumlah(5);
      Swal.fire({
        title: "",
        text: "Pengisian telah selesai",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
      this.$router.push({ name: "dashboard" });
    },

    unduhFile(url) {
      //url = 'https://penilaiankinerja.bkpm.go.id:3010/public/upload/1653981357_IJAZAH%20ZAENAL.pdf'
      window.open(url, "_blank");
      // axios.get(url, { responseType: 'blob' },
      // {
      //     headers:{
      //         'Access-Control-Allow-Origin': '*',
      //     }
      // }
      // )
      //     .then(response => {
      //         console.log(response)
      //         window.open(url, '_blank')
      //     }).catch(error => {
      //         Swal.fire({
      //             title: "",
      //             text: "Tidak ada file!",
      //             icon: "danger",
      //             confirmButtonClass: "btn btn-danger"
      //         });
      //     })
    },

    exportExcel(data) {
      window.open(
        this.url + `/export/pm_pemda/` + this.user.id_ptsp + `/` + data,
        "_blank"
      );
      // Swal.fire({
      //     title: 'Export PM PTSP Pemda',
      //     showDenyButton: true,
      //     showCancelButton: false,
      //     confirmButtonText: '2022',
      //     denyButtonText: '2023',
      //     }).then((result) => {
      //     /* Read more about isConfirmed, isDenied below */
      //     if (result.isConfirmed) {
      //         window.open(this.url+`/export/pm_pemda/`+this.user.id_ptsp+`/2022`, '_blank')
      //     } else if (result.isDenied) {
      //         window.open(this.url+`/export/pm_pemda/`+this.user.id_ptsp+`/2023`, '_blank')
      //     }
      //     })
    },

    previewFilesp1a1_file1() {
      this.$refs.p1a1_file1.click();
    },
    onFileChangedp1a1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        console.log(this.selectedFile);
        this.$loading(true);
        this.pm.p1a1_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1a1_file1", this.pm.p1a1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response)

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1a1_file2() {
      this.$refs.p1a1_file2.click();
    },
    onFileChangedp1a1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1a1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1a1_file2", this.pm.p1a1_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });

            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1a1_file3() {
      this.$refs.p1a1_file3.click();
    },
    onFileChangedp1a1_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1a1_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1a1_file3", this.pm.p1a1_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1a2_file1() {
      this.$refs.p1a2_file1.click();
    },
    onFileChangedp1a2_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1a2_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1a2_file1", this.pm.p1a2_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1a3_file1() {
      this.$refs.p1a3_file1.click();
    },
    onFileChangedp1a3_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1a3_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1a3_file1", this.pm.p1a3_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1a3_file2() {
      this.$refs.p1a3_file2.click();
    },
    onFileChangedp1a3_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1a3_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1a3_file2", this.pm.p1a3_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1a3_file3() {
      this.$refs.p1a3_file3.click();
    },
    onFileChangedp1a3_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1a3_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1a3_file3", this.pm.p1a3_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1a4_file1() {
      this.$refs.p1a4_file1.click();
    },
    onFileChangedp1a4_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1a4_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1a4_file1", this.pm.p1a4_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });

            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1a4_file2() {
      this.$refs.p1a4_file2.click();
    },
    onFileChangedp1a4_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1a4_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1a4_file2", this.pm.p1a4_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });

            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1b1_file1() {
      this.$refs.p1b1_file1.click();
    },
    onFileChangedp1b1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1b1_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1b1_file1", this.pm.p1b1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });

            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1b1_file2() {
      this.$refs.p1b1_file2.click();
    },
    onFileChangedp1b1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1b1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1b1_file2", this.pm.p1b1_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });

            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1b2_file1() {
      this.$refs.p1b2_file1.click();
    },
    onFileChangedp1b2_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1b2_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1b2_file1", this.pm.p1b2_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });

            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1c1_file1() {
      this.$refs.p1c1_file1.click();
    },
    onFileChangedp1c1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1c1_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1c1_file1", this.pm.p1c1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1c1_file2() {
      this.$refs.p1c1_file2.click();
    },
    onFileChangedp1c1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1c1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1c1_file2", this.pm.p1c1_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1c2_file1() {
      this.$refs.p1c2_file1.click();
    },
    onFileChangedp1c2_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.pm.p1c2_file1 = this.selectedFile;

        this.$loading(true);
        let formData = new FormData();
        formData.append("p1c2_file1", this.pm.p1c2_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            if (this.selectedFile == null) {
              this.loadData();
              this.$bvToast.toast(
                "Lampiran Gagal Disubmit, tambahkan lampiran",
                {
                  title: `Failed `,
                  variant: `danger`,
                  solid: true,
                }
              );
            } else {
              this.loadData();
              this.$bvToast.toast(error.response.data.message, {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
            }
            return error;
          });
      }
    },

    onFileChangedp1c2_file1_radio() {
      this.$loading(true);
      let formData = new FormData();
      formData.append("p1c2_nilai", this.pm.p1c2_nilai);
      axios
        .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          // console.log(response);

          this.loadData();

          return response;
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
          if (this.selectedFile == null) {
            this.loadData();
            this.$bvToast.toast("Lampiran Gagal Disubmit, tambahkan lampiran", {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          } else {
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          }
          return error;
        });
    },

    previewFilesp1c3_file1() {
      this.$refs.p1c3_file1.click();
    },
    onFileChangedp1c3_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.pm.p1c3_file1 = this.selectedFile;
        this.$loading(true);
        let formData = new FormData();
        formData.append("p1c3_file1", this.pm.p1c3_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            if (this.selectedFile == null) {
              this.loadData();
              this.$bvToast.toast(
                "Lampiran Gagal Disubmit, tambahkan lampiran",
                {
                  title: `Failed `,
                  variant: `danger`,
                  solid: true,
                }
              );
            } else {
              this.loadData();
              this.$bvToast.toast(error.response.data.message, {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
            }
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    onFileChangedp1c3_file1_radio() {
      this.$loading(true);
      let formData = new FormData();
      formData.append("p1c3_nilai", this.pm.p1c3_nilai);
      axios
        .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          // console.log(response);

          this.loadData();

          return response;
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
          if (this.selectedFile == null) {
            this.loadData();
            this.$bvToast.toast("Lampiran Gagal Disubmit, tambahkan lampiran", {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          } else {
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          }
          return error;
        });
      console.log(this.selectedFile);
    },

    previewFilesp1c4_file1() {
      this.$refs.p1c4_file1.click();
    },
    onFileChangedp1c4_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1c4_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1c4_file1", this.pm.p1c4_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1c4_file2() {
      this.$refs.p1c4_file2.click();
    },
    onFileChangedp1c4_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1c4_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1c4_file2", this.pm.p1c4_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1c4_file3() {
      this.$refs.p1c4_file3.click();
    },
    onFileChangedp1c4_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1c4_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1c4_file3", this.pm.p1c4_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d1_file1() {
      this.$refs.p1d1_file1.click();
    },
    onFileChangedp1d1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d1_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d1_file1", this.pm.p1d1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d1_file2() {
      this.$refs.p1d1_file2.click();
    },
    onFileChangedp1d1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d1_file2", this.pm.p1d1_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d1_file3() {
      this.$refs.p1d1_file3.click();
    },
    onFileChangedp1d1_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d1_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d1_file3", this.pm.p1d1_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d1_file4() {
      this.$refs.p1d1_file4.click();
    },
    onFileChangedp1d1_file4(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d1_file4 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d1_file4", this.pm.p1d1_file4);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d1_file5() {
      this.$refs.p1d1_file5.click();
    },
    onFileChangedp1d1_file5(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d1_file5 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d1_file5", this.pm.p1d1_file5);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d2_file1() {
      this.$refs.p1d2_file1.click();
    },
    onFileChangedp1d2_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.pm.p1d2_file1 = this.selectedFile;

        this.$loading(true);
        let formData = new FormData();
        formData.append("p1d2_file1", this.pm.p1d2_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            if (this.selectedFile == null) {
              this.loadData();
              this.$bvToast.toast(
                "Lampiran Gagal Disubmit, tambahkan lampiran",
                {
                  title: `Failed `,
                  variant: `danger`,
                  solid: true,
                }
              );
            } else {
              this.loadData();
              this.$bvToast.toast(error.response.data.message, {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
            }
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    onFileChangedp1d2_file1_radio() {
      this.$loading(true);
      let formData = new FormData();
      formData.append("p1d2_nilai", this.pm.p1d2_nilai);
      axios
        .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          // console.log(response);

          this.loadData();

          return response;
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
          if (this.selectedFile == null) {
            this.loadData();
            this.$bvToast.toast("Lampiran Gagal Disubmit, tambahkan lampiran", {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          } else {
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          }
          return error;
        });
      console.log(this.selectedFile);
    },

    previewFilesp1d3_file1() {
      this.$refs.p1d3_file1.click();
    },
    onFileChangedp1d3_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d3_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d3_file1", this.pm.p1d3_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d3_file2() {
      this.$refs.p1d3_file2.click();
    },
    onFileChangedp1d3_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d3_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d3_file2", this.pm.p1d3_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d3_file3() {
      this.$refs.p1d3_file3.click();
    },
    onFileChangedp1d3_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d3_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d3_file3", this.pm.p1d3_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d4_file1() {
      this.$refs.p1d4_file1.click();
    },
    onFileChangedp1d4_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d4_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d4_file1", this.pm.p1d4_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d4_file2() {
      this.$refs.p1d4_file2.click();
    },
    onFileChangedp1d4_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d4_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d4_file2", this.pm.p1d4_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d4_file3() {
      this.$refs.p1d4_file3.click();
    },
    onFileChangedp1d4_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d4_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d4_file3", this.pm.p1d4_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d4_file4() {
      this.$refs.p1d4_file4.click();
    },
    onFileChangedp1d4_file4(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d4_file4 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d4_file4", this.pm.p1d4_file4);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d4_file5() {
      this.$refs.p1d4_file5.click();
    },
    onFileChangedp1d4_file5(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d4_file5 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d4_file5", this.pm.p1d4_file5);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d4_file6() {
      this.$refs.p1d4_file6.click();
    },
    onFileChangedp1d4_file6(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d4_file6 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d4_file6", this.pm.p1d4_file6);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d5_file1() {
      this.$refs.p1d5_file1.click();
    },
    onFileChangedp1d5_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d5_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d5_file1", this.pm.p1d5_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d5_file2() {
      this.$refs.p1d5_file2.click();
    },
    onFileChangedp1d5_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d5_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d5_file2", this.pm.p1d5_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d5_file3() {
      this.$refs.p1d5_file3.click();
    },
    onFileChangedp1d5_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d5_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d5_file3", this.pm.p1d5_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d5_file4() {
      this.$refs.p1d5_file4.click();
    },
    onFileChangedp1d5_file4(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d5_file4 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d5_file4", this.pm.p1d5_file4);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d6_file1() {
      this.$refs.p1d6_file1.click();
    },
    onFileChangedp1d6_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d6_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d6_file1", this.pm.p1d6_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d6_file2() {
      this.$refs.p1d6_file2.click();
    },
    onFileChangedp1d6_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d6_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d6_file2", this.pm.p1d6_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d6_file3() {
      this.$refs.p1d6_file3.click();
    },
    onFileChangedp1d6_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d6_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d6_file3", this.pm.p1d6_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp1d6_file4() {
      this.$refs.p1d6_file4.click();
    },
    onFileChangedp1d6_file4(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p1d6_file4 = this.selectedFile;
        let formData = new FormData();
        formData.append("p1d6_file4", this.pm.p1d6_file4);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    //   sarpras

    previewFilesp3a1_file1() {
      this.$refs.p3a1_file1.click();
    },
    onFileChangedp3a1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a1_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a1_file1", this.pm.p3a1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a1_file2() {
      this.$refs.p3a1_file2.click();
    },
    onFileChangedp3a1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a1_file2", this.pm.p3a1_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a1_file3() {
      this.$refs.p3a1_file3.click();
    },
    onFileChangedp3a1_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a1_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a1_file3", this.pm.p3a1_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a2_file1() {
      this.$refs.p3a2_file1.click();
    },
    onFileChangedp3a2_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a2_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a2_file1", this.pm.p3a2_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a2_file2() {
      this.$refs.p3a2_file2.click();
    },
    onFileChangedp3a2_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a2_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a2_file2", this.pm.p3a2_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a2_file3() {
      this.$refs.p3a2_file3.click();
    },
    onFileChangedp3a2_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a2_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a2_file3", this.pm.p3a2_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a3_file1() {
      this.$refs.p3a3_file1.click();
    },
    onFileChangedp3a3_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a3_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a3_file1", this.pm.p3a3_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a3_file2() {
      this.$refs.p3a3_file2.click();
    },
    onFileChangedp3a3_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a3_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a3_file2", this.pm.p3a3_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a3_file3() {
      this.$refs.p3a3_file3.click();
    },
    onFileChangedp3a3_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a3_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a3_file3", this.pm.p3a3_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a4_file1() {
      this.$refs.p3a4_file1.click();
    },
    onFileChangedp3a4_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a4_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a4_file1", this.pm.p3a4_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a4_file2() {
      this.$refs.p3a4_file2.click();
    },
    onFileChangedp3a4_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a4_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a4_file2", this.pm.p3a4_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3a4_file3() {
      this.$refs.p3a4_file3.click();
    },
    onFileChangedp3a4_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3a4_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3a4_file3", this.pm.p3a4_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b1_file1() {
      this.$refs.p3b1_file1.click();
    },
    onFileChangedp3b1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b1_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b1_file1", this.pm.p3b1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);
            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b1_file2() {
      this.$refs.p3b1_file2.click();
    },
    onFileChangedp3b1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b1_file2", this.pm.p3b1_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b1_file3() {
      this.$refs.p3b1_file3.click();
    },
    onFileChangedp3b1_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b1_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b1_file3", this.pm.p3b1_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b1_file4() {
      this.$refs.p3b1_file4.click();
    },
    onFileChangedp3b1_file4(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b1_file4 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b1_file4", this.pm.p3b1_file4);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b1_file5() {
      this.$refs.p3b1_file5.click();
    },
    onFileChangedp3b1_file5(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b1_file5 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b1_file5", this.pm.p3b1_file5);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b2_file1() {
      this.$refs.p3b2_file1.click();
    },
    onFileChangedp3b2_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b2_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b2_file1", this.pm.p3b2_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b2_file2() {
      this.$refs.p3b2_file2.click();
    },
    onFileChangedp3b2_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b2_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b2_file2", this.pm.p3b2_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b2_file3() {
      this.$refs.p3b2_file3.click();
    },
    onFileChangedp3b2_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b2_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b2_file3", this.pm.p3b2_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b3_file1() {
      this.$refs.p3b3_file1.click();
    },
    onFileChangedp3b3_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b3_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b3_file1", this.pm.p3b3_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b3_file2() {
      this.$refs.p3b3_file2.click();
    },
    onFileChangedp3b3_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b3_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b3_file2", this.pm.p3b3_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b3_file3() {
      this.$refs.p3b3_file3.click();
    },
    onFileChangedp3b3_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b3_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b3_file3", this.pm.p3b3_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b3_file4() {
      this.$refs.p3b3_file4.click();
    },
    onFileChangedp3b3_file4(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b3_file4 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b3_file4", this.pm.p3b3_file4);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp3b3_file5() {
      this.$refs.p3b3_file5.click();
    },
    onFileChangedp3b3_file5(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p3b3_file5 = this.selectedFile;
        let formData = new FormData();
        formData.append("p3b3_file5", this.pm.p3b3_file5);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4a1_file1() {
      this.$refs.p4a1_file1.click();
    },
    onFileChangedp4a1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4a1_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4a1_file1", this.pm.p4a1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4a1_file2() {
      this.$refs.p4a1_file2.click();
    },
    onFileChangedp4a1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4a1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4a1_file2", this.pm.p4a1_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4a1_file3() {
      this.$refs.p4a1_file3.click();
    },
    onFileChangedp4a1_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4a1_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4a1_file3", this.pm.p4a1_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4a1_file4() {
      this.$refs.p4a1_file4.click();
    },
    onFileChangedp4a1_file4(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4a1_file4 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4a1_file4", this.pm.p4a1_file4);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4a1_file5() {
      this.$refs.p4a1_file5.click();
    },
    onFileChangedp4a1_file5(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4a1_file5 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4a1_file5", this.pm.p4a1_file5);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4b1_file1() {
      this.$refs.p4b1_file1.click();
    },
    onFileChangedp4b1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4b1_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4b1_file1", this.pm.p4b1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4b1_file2() {
      this.$refs.p4b1_file2.click();
    },
    onFileChangedp4b1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4b1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4b1_file2", this.pm.p4b1_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4b1_file3() {
      this.$refs.p4b1_file3.click();
    },
    onFileChangedp4b1_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4b1_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4b1_file3", this.pm.p4b1_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4b1_file4() {
      this.$refs.p4b1_file4.click();
    },
    onFileChangedp4b1_file4(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4b1_file4 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4b1_file4", this.pm.p4b1_file4);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4b1_file5() {
      this.$refs.p4b1_file5.click();
    },
    onFileChangedp4b1_file5(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4b1_file5 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4b1_file5", this.pm.p4b1_file5);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4b2_file1() {
      this.$refs.p4b2_file1.click();
    },
    onFileChangedp4b2_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4b2_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4b2_file1", this.pm.p4b2_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4b2_file2() {
      this.$refs.p4b2_file2.click();
    },
    onFileChangedp4b2_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4b2_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4b2_file2", this.pm.p4b2_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4b2_file3() {
      this.$refs.p4b2_file3.click();
    },
    onFileChangedp4b2_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4b2_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4b2_file3", this.pm.p4b2_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4b3_file1() {
      this.$refs.p4b3_file1.click();
    },
    onFileChangedp4b3_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.pm.p4b3_file1 = this.selectedFile;
        this.$loading(true);
        let formData = new FormData();
        formData.append("p4b3_file1", this.pm.p4b3_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            if (this.selectedFile == null) {
              this.loadData();
              this.$bvToast.toast(
                "Lampiran Gagal Disubmit, tambahkan lampiran",
                {
                  title: `Failed `,
                  variant: `danger`,
                  solid: true,
                }
              );
            } else {
              this.loadData();
              this.$bvToast.toast(error.response.data.message, {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
            }
            return error;
          });
        console.log(this.selectedFile);
      }
    },
    onFileChangedp4b3_file1_radio() {
      this.$loading(true);
      let formData = new FormData();
      formData.append("p4b3_nilai", this.pm.p4b3_nilai);
      axios
        .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          // console.log(response);
          this.loadData();

          return response;
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
          if (this.selectedFile == null) {
            this.loadData();
            this.$bvToast.toast("Lampiran Gagal Disubmit, tambahkan lampiran", {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          } else {
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          }
          return error;
        });
    },

    previewFilesp4b4_file1() {
      this.$refs.p4b4_file1.click();
    },
    onFileChangedp4b4_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.pm.p4b4_file1 = this.selectedFile;

        this.$loading(true);
        let formData = new FormData();
        formData.append("p4b4_file1", this.pm.p4b4_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            if (this.selectedFile == null) {
              this.loadData();
              this.$bvToast.toast(
                "Lampiran Gagal Disubmit, tambahkan lampiran",
                {
                  title: `Failed `,
                  variant: `danger`,
                  solid: true,
                }
              );
            } else {
              this.loadData();
              this.$bvToast.toast(error.response.data.message, {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
            }
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp4b4_file2() {
      this.$refs.p4b4_file2.click();
    },
    onFileChangedp4b4_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p4b4_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p4b4_file2", this.pm.p4b4_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    //   keluaran
    previewFilesp5a1_file1() {
      this.$refs.p5a1_file1.click();
    },
    onFileChangedp5a1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.pm.p5a1_file1 = this.selectedFile;

        this.$loading(true);
        let formData = new FormData();
        formData.append("p5a1_file1", this.pm.p5a1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);
            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);

            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    onFileChangedp5a1_file1_radio() {
      this.$loading(true);
      let formData = new FormData();
      formData.append("p5a1_nilai", this.pm.p5a1_nilai);
      axios
        .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          // console.log(response);
          this.loadData();

          return response;
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
          if (this.selectedFile == null) {
            this.loadData();
            this.$bvToast.toast("Lampiran Gagal Disubmit, tambahkan lampiran", {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          } else {
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          }
          return error;
        });
    },

    previewFilesp5a1_file2() {
      this.$refs.p5a1_file2.click();
    },
    onFileChangedp5a1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p5a1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p5a1_file2", this.pm.p5a1_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
        console.log(this.selectedFile);
      }
    },

    previewFilesp5a2_file1() {
      this.$refs.p5a2_file1.click();
    },
    onFileChangedp5a2_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.pm.p5a2_file1 = this.selectedFile;

        this.$loading(true);
        let formData = new FormData();
        formData.append("p5a2_file1", this.pm.p5a2_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);
            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            if (this.selectedFile == null) {
              this.loadData();
              this.$bvToast.toast(
                "Lampiran Gagal Disubmit, tambahkan lampiran",
                {
                  title: `Failed `,
                  variant: `danger`,
                  solid: true,
                }
              );
            } else {
              this.loadData();
              this.$bvToast.toast(error.response.data.message, {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
            }
            return error;
          });
      }
    },

    onFileChangedp5a2_file1_radio() {
      this.$loading(true);
      let formData = new FormData();
      formData.append("p5a2_nilai", this.pm.p5a2_nilai);
      axios
        .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          // console.log(response);
          this.loadData();

          return response;
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
          if (this.selectedFile == null) {
            this.loadData();
            this.$bvToast.toast("Lampiran Gagal Disubmit, tambahkan lampiran", {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          } else {
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
          }
          return error;
        });
      console.log(this.selectedFile);
    },

    previewFilesp5b1_file1() {
      this.$refs.p5b1_file1.click();
    },
    onFileChangedp5b1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.pm.p5b1_file1 = this.selectedFile;

        this.$loading(true);
        let formData = new FormData();
        formData.append("p5b1_file1", this.pm.p5b1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
      }
    },

    onFileChangedp5b1_file1_radio() {
      this.$loading(true);
      let formData = new FormData();
      formData.append("p5b1_nilai", this.pm.p5b1_nilai);
      axios
        .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          // console.log(response);
          this.loadData();

          return response;
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
          this.loadData();
          this.$bvToast.toast(error.response.data.message, {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
        });
      console.log(this.selectedFile);
    },

    previewFilesp5b1_file2() {
      this.$refs.p5b1_file2.click();
    },
    onFileChangedp5b1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p5b1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p5b1_file2", this.pm.p5b1_file2);
        formData.append("p5b1_nilai", this.pm.p5b1_nilai);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
      }
    },

    previewFilesp5c1_file1() {
      this.$refs.p5c1_file1.click();
    },
    onFileChangedp5c1_file1(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p5c1_file1 = this.selectedFile;
        let formData = new FormData();
        formData.append("p5c1_file1", this.pm.p5c1_file1);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
      }
    },

    previewFilesp5c1_file2() {
      this.$refs.p5c1_file2.click();
    },
    onFileChangedp5c1_file2(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p5c1_file2 = this.selectedFile;
        let formData = new FormData();
        formData.append("p5c1_file2", this.pm.p5c1_file2);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
      }
    },

    previewFilesp5c1_file3() {
      this.$refs.p5c1_file3.click();
    },
    onFileChangedp5c1_file3(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p5c1_file3 = this.selectedFile;
        let formData = new FormData();
        formData.append("p5c1_file3", this.pm.p5c1_file3);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
      }
    },

    previewFilesp5c1_file4() {
      this.$refs.p5c1_file4.click();
    },
    onFileChangedp5c1_file4(e) {
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);
        Swal.fire({
          title: "",
          text: "File terlalu besar, pilih file maksimal 500 kb",
          icon: "danger",
          confirmButtonClass: "btn btn-danger",
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        this.pm.p5c1_file4 = this.selectedFile;
        let formData = new FormData();
        formData.append("p5c1_file4", this.pm.p5c1_file4);
        axios
          .post(this.url + "/pm_ptsp_pemda_2023/" + this.idpm, formData, {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast("Lampiran Berhasil Disubmit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);
            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.loadData();
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
      }
    },

    loadData() {
      axios
        .get(this.url + `/pm_ptsp_pemda_2023?filter=id,=,` + this.idpm, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.pm = response.data.data[0];
          console.log(this.pm.p1a1_file1);
        });
    },

    getdata() {
      $("#example").DataTable().destroy();
      this.loadsdm();
    },
    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          order: [[0, "desc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
        }).draw();
      }, 300);
    },

    loadsdm() {
      // tabel sdm bawah
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,kadis`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.kelembagaan = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,kabidpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.perizinanberusaha = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,kasiepb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.kepalaseksi = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,foffpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.stafffoizin = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,boffpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.staffboizin = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,hdeskpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.staffhd = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,kabiddal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.kepaladalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,kasiedal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.ksdalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,foffdal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.sfodalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,boffdal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.sbodalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;jabatan,=,hdeskdal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.shddalaks = response.data.data;
        });

      // tabel sdm atas
      axios
        .get(this.url + `/data_sdm_2023?filter=idptsp,=,` + this.user.id_ptsp, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.sdm = response.data.data;
          this.initDatatable();
        });
    },
    getperlembaga(id) {
      axios
        .get(this.url + `/data_sdm_2023?id=${id}`, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.getlembaga = response.data.data;
          // console.log(this.getlembaga)
        });
    },

    hapussdm(id) {
      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Anda tidak bisa mengembalikan file ini",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus File",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(this.url + `/data_sdm_2023/${id}`, {
              headers: {
                "Content-type": "application/json",
                xth: this.token,
              },
            })
            .then((res) => {
              this.$bvToast.toast("Berhasil Dihapus", {
                title: `Success `,
                variant: `success`,
                solid: true,
              });
              // console.log(res);
              this.getjumlah("2");
              return res;
            })
            .catch((err) => {
              // console.log(err)
              this.$bvToast.toast("Gagal Dihapus", {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
              return err;
            });
        }
      });
    },

    getjumlah(data) {
      console.log("ini");
      axios
        .put(
          this.url + "/pm_ptsp_pemda_2023_final",
          {
            idptsp: this.user.id_ptsp,
            tahun: 2024,
            form: data,
          },
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((res) => {
          if (data != 2) {
            this.$bvToast.toast("Berhasil Disimpan", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
          }
          console.log(res);
          this.getdata();
          this.loadData();
          return res;
        })
        .catch((err) => {
          this.loadData();
          this.$bvToast.toast("Gagal Disimpan", {
            title: `Success `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
    },

    handleSubmit() {
      this.$validator.validateAll("form-1").then((result) => {
        if (result) {
          console.log("tetst");
          this.$loading(true);

          let formData = new FormData();
          formData.append("idptsp", this.user.id_ptsp);
          formData.append("nama_sdm", this.nama_sdm);
          formData.append("nip", this.nip);
          formData.append("pendidikan", this.pendidikan);
          formData.append("jabatan", this.jabatan);
          formData.append("lama_bertugas", this.lama_bertugas);
          formData.append("ser_bpb_dasar", this.ser_bpb_dasar);
          formData.append("ser_bpb_lanjutan", this.ser_bpb_lanjutan);
          formData.append("ser_bpb_sektoral", this.ser_bpb_sektoral);
          formData.append("ser_oss", this.ser_oss);
          formData.append("ser_bha", this.ser_bha);
          formData.append("ser_ppb", this.ser_ppb);
          formData.append("filename", this.filename);
          axios
            .post(this.url + "/data_sdm_2023", formData, {
              // _method: 'patch',
              headers: {
                xth: this.token,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              this.$loading(false);
              this.$bvToast.toast("Berhasil Disimpan", {
                title: `Success `,
                variant: `success`,
                solid: true,
              });
              this.$nextTick(() => {
                this.$bvModal.hide("modal-prevent-closing");
              });
              this.nama_sdm = null;
              this.nip = null;
              this.pendidikan = null;
              this.jabatan = null;
              this.lama_bertugas = null;
              this.ser_bpb_dasar = null;
              this.ser_bpb_lanjutan = null;
              this.ser_bpb_sektoral = null;
              this.ser_oss = null;
              this.ser_bha = null;
              this.ser_ppb = null;
              this.filename = null;
              // console.log(res);
              this.getjumlah("2");
              return res;
            })
            .catch((err) => {
              this.$loading(false);
              console.log(err);
              this.$bvToast.toast("Gagal Disimpan", {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
              return err;
            });
        }
      });

      // Push the name to submitted names
      // this.submittedNames.push(this.name)
      // Hide the modal manually
    },

    deleteFile(data) {
      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Anda tidak bisa mengembalikan file ini",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus File",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .put(
              this.url + "/pm_ptsp_pemda_2023_delete_lampiran",
              {
                idptsp: this.user.id_ptsp,
                tahun: 2024,
                field: data,
              },
              {
                headers: {
                  xth: this.token,
                },
              }
            )
            .then((res) => {
              this.$bvToast.toast("Berhasil Dihapus", {
                title: `Success `,
                variant: `success`,
                solid: true,
              });
              this.loadData();
              this.fileInputKey++;
              return res;
            })
            .catch((err) => {
              console.log(err);
              this.loadData();
              this.$bvToast.toast("Gagal Dihapus", {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
              return err;
            });
        }
      });
    },

    validasiFileTambahSDM() {
      var fi = document.getElementById("fileSDM");
      // Check if any file is selected.
      if (fi.files.length > 0) {
        for (var i = 0; i <= fi.files.length - 1; i++) {
          var fsize = fi.files.item(i).size;
          var file = Math.round(fsize / 1024);
          // The size of the file.
          if (file >= 500) {
            console.log(file);
            alert("File terlalu besar, pilih file maksimal 500kb");
            // this.pm.l_perizinanoss_1 = null;
            // this.pm.l_perizinanoss_1 = null;
            this.filename = null;
          }
        }
      }
    },

    editsdm(id) {
      let formData = new FormData();
      formData.append("idptsp", this.user.id_ptsp);
      formData.append("nama_sdm", this.getlembaga.nama_sdm);
      formData.append("nip", this.getlembaga.nip);
      formData.append("pendidikan", this.getlembaga.pendidikan);
      formData.append("jabatan", this.getlembaga.jabatan);
      formData.append("lama_bertugas", this.getlembaga.lama_bertugas);
      formData.append("ser_bpb_dasar", this.getlembaga.ser_bpb_dasar);
      formData.append("ser_bpb_lanjutan", this.getlembaga.ser_bpb_lanjutan);
      formData.append("ser_bpb_sektoral", this.getlembaga.ser_bpb_sektoral);
      formData.append("ser_oss", this.getlembaga.ser_oss);
      formData.append("ser_bha", this.getlembaga.ser_bha);
      formData.append("ser_ppb", this.getlembaga.ser_ppb);
      formData.append("filename", this.getlembaga.filename);
      axios
        .post(this.url + "/data_sdm_2023/" + id, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          this.$refs.fileupload.value = null;
          this.$nextTick(() => {
            this.$bvModal.hide("modal-prevent-closing");
          });
          // console.log(res);
          this.getjumlah("2");
          return res;
        })
        .catch((err) => {
          this.$loading(false);
          console.log(err);
          this.$bvToast.toast("Gagal Disimpan", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
    },
    downloadItem(url) {
      url =
        "https://penilaiankinerja.bkpm.go.id:3010/public/upload/1653981357_IJAZAH%20ZAENAL.pdf";
      label = "test";
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    handleOk() {
        // Prevent modal from closing
        console.log("tutup")
        this.$bvModal.hide("modal-prevent-closing");
      },
  },
};
</script>
  
<style scoped>
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 768px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 10px 1rem 1rem 1rem;
  }

  /* tr:nth-child(odd) {
      background: #E1EDFF;
    } */

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    /* padding-left: 50%; */
  }

  th {
    display: none;
  }

  /* td:before { */
  /* Now like a table header */
  /* position: absolute; */
  /* Top/left values mimic padding */
  /* top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap; */
  /* } */

  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  /* td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Indikator"; }
		td:nth-of-type(3):before { content: "Status"; }
		td:nth-of-type(4):before { content: "Jawaban"; }
        td:nth-of-type(5):before { content: "Unggah"; } 
        td:nth-of-type(6):before { content: "Download"; }  */
}
</style>